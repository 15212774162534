import React, { useEffect, useMemo, useState } from 'react';
import S from '../../styles/profile';
import imageBadge from '../../assets/images/profile/badge.png';
import { t } from '../../utils';
import { message, Spin, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import PersonalInfo from './PersonalInfo';
import SecurityInfo from './SecurityInfo';
import { fetchById } from '../../api/users';
import { useParams, useHistory } from 'react-router-dom';

const { TabPane } = Tabs;

export default function Profile(props) {
  const myAccount = useSelector(state => state.auth.user || {});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const history = useHistory()
  const { districts, regions } = useSelector(state => state.handbooks || {});
  const [activeKey, setActiveKey] = useState('personal');
  const params = useParams();
  const { region, district } = useMemo(() => {
    return {
      region: regions?.find(item => item.id === data.region),
      district: districts?.find(item => item.id === data.district),
    }
  }, [data]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        if (parseInt(params.id) === parseInt(myAccount.id)) {
          return history.push('/profile');
        }
        const data = await fetchById(params.id);
        if (data.error) {
          return message.error(t('Hatolik yuz berdi'));
        }
        setData(data);
        setLoading(false);
      }
      catch (err) {
        console.error(err);
        setLoading(false)
      }
    })()
  }, []);

  return (
    <>
      <h2>{t("Profil")}</h2>
      <S.Container className="b-1 b-radius">
        <S.Header className="profile p-4 bb-1">
          <img src={imageBadge} alt="DTP" className="profile__img" />
          <div className="profile__info">
            <h3 className="profile__name">{data.username}</h3>
            <h4 className="profile__location">{`${region ? region[`name_${myAccount.lang}`] : ''} ${region ? ',' : ''} ${district ? district[`name_${myAccount.lang}`] : ''}`}</h4>
          </div>
        </S.Header>
        <S.Form className="p-4">

          {
            loading ?
              <Spin style={{
                backgroundColor: 'rgb(250 250 250)',
                height: 300,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }} />
              :
              <Tabs
                type="card"
                className="mb-0"
                onChange={(key) => setActiveKey(key)}
                activeKey={activeKey}
              >
                <TabPane tab={t("Shaxsiy")} key="personal" className="p-3 bx-1 bb-1 b-radius-right-bottom b-radius-left-bottom">
                  <PersonalInfo data={data} />
                </TabPane>
                {
                  myAccount.is_superuser ?
                    <TabPane tab={t("Avtorizatsiya")} key="security" className="p-3 bx-1 bb-1 b-radius-right-bottom b-radius-left-bottom">
                      <SecurityInfo data={data} />
                    </TabPane>
                    :
                    null
                }

              </Tabs>
          }
        </S.Form>
      </S.Container>

    </>
  )
}
