import React, { useState, lazy, Suspense, useMemo } from "react";
import { Space, Tabs, Tag, Row, Col, Spin, message } from "antd";

import { useHistory } from "react-router-dom";
import CardHeader from "./Cardheader";
import S from "../../../styles/cards";
import { t } from "../../../utils";
import StepOne from "./StepOne";
import { HotKeys } from "react-hotkeys";
import NewCardContext from "../../../contexts/newCardContext";
import { useSelector } from "react-redux";
import HelpSidebarSection from "../../../components/HelpSidebarSection";
import moment from "moment";
import { fieldscheckOnStepsChange } from "../../../utils/card";
import { errorMessages } from "../../../constants/cardOptions";

const { TabPane } = Tabs;
const StepTwo = lazy(() => import("./StepTwo"));
const StepThree = lazy(() => import("./StepThree"));
const StepFour = lazy(() => import("./StepFour"));
const Map = lazy(() => import("../../../components/Map/index"));

const keyMap = {
  ACTIVE_TAB: ["f5", "f6", "f7", "f8"],
};

export default function AddCard(props) {
  const history = useHistory();
  const [mapVisible, setMapVisible] = useState(false);
  const stepOne = useSelector((state) => state.card);
  const activeTab =
    new URLSearchParams(props.location.search).get("active_tab") || "f5";
  const handleActiveTab = (value) => {
    // const res = fieldscheckOnStepsChange({
    //   fields,
    //   redirecHandler: (tab) => history.push(`${props.location.pathname}?active_tab=` + tab),
    // });
    // if (res) {
    fieldsHandler(value);
    history.push(`${props.location.pathname}?active_tab=` + value);
    // }
  };

  const fields = useSelector((state) => state.card || {});
  const { accident_day, accident_month, accident_year, date_accident } = fields;

  const handlers = {
    ACTIVE_TAB: (event) => handleActiveTab(event.key?.toLowerCase()),
  };

  const contextValue = {
    handleActiveTab,
    setMapVisible,
    activeTab,
  };

  const stats = useMemo(
    () =>
      [...stepOne.participants, ...stepOne.vehicles].reduce(
        (total, item) => {
          let injured = item.health_condition === "injured" ? 1 : 0;
          let dead = item.health_condition === "died" ? 1 : 0;
          item.participants?.forEach((i) => {
            if (i.health_condition === "injured") {
              injured += 1;
            } else if (i.health_condition === "died") {
              dead += 1;
            }
          });

          total.dead += dead;
          total.injured += injured;
          total.total += item?.participants ? item?.participants.length : 1;
          return total;
        },
        { injured: 0, dead: 0, total: 0 }
      ),
    [stepOne]
  );

  function fieldsHandler(props) {
    // console.log(fields);
  }

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <NewCardContext.Provider value={contextValue}>
        <S.NewCard>
          <CardHeader />
          <Space className="statistics mb-4" size="large">
            <Space>
              <h1>{stats.total === 0 ? "-" : stats.total}</h1>
              <p>{t("YTH ishtirokchilari")}</p>
            </Space>
            <Space>
              <h1>{stats.dead === 0 ? "-" : stats.dead}</h1>
              <p>{t("Xalok bo'lganlar")}</p>
            </Space>
            <Space>
              <h1>{stats.injured === 0 ? "-" : stats.injured}</h1>
              <p>{t("Jarohatlanganlar")}</p>
            </Space>
          </Space>
          {accident_day !== "Sana" &&
            accident_day !== "00" &&
            accident_day !== "0" &&
            accident_month !== "oy" &&
            accident_month !== "00" &&
            accident_month !== "0" &&
            accident_year !== "Yil" &&
            accident_year !== "00" &&
            accident_year !== "0" && (
              <div
                style={{
                  position: "relative",
                  marginTop: "-2.2rem",
                  marginBottom: "2rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                  }}
                >
                  <p>{accident_day === null ? "--" : accident_day}.</p>
                  <p>{accident_month === null ? "--" : accident_month}.</p>
                  <p>{accident_year === null ? "--" : accident_year}</p>
                  {/* <p>{date_accident ? moment(date_accident).format('DD.MM.YYYY') : '---'}</p> */}
                </div>
                <p style={{ marginLeft: "10px" }}>
                  {t("YTH sodir bo'lgan vaqt")}{" "}
                </p>
              </div>
            )}
          {/* ------- Tabs ------ */}
          <section className="b-1 p-4 pl-0 b-radius-10">
            <div className="pl-4">
              <Tabs
                activeKey={activeTab}
                onChange={(key) => handleActiveTab(key)}
                className="main-tab scrollable"
                // tabBarExtraContent={{
                //   right: <Button className="grey" onClick={() => handleActiveTab('history')}>
                //     {t("O'zgarishlar tarixi")} <BiHourglass size='20px' className="ml-0" />
                //   </Button>
                // }}
              >
                <TabPane
                  className="pt-5"
                  tab={
                    <h3 className="mb-0 tab-title">
                      {t("1. Umumiy ma'lumotlar")}{" "}
                      <Tag className="f-code">F5</Tag>{" "}
                    </h3>
                  }
                  key="f5"
                >
                  <Row>
                    <Col span={19}>
                      <StepOne />
                    </Col>
                    <HelpSidebarSection />
                  </Row>
                </TabPane>
                <TabPane
                  className="pt-5"
                  tab={
                    <h3 className="mb-0 tab-title">
                      {t("2. YTH ishtirokchilari")}{" "}
                      <Tag className="f-code">F6</Tag>{" "}
                    </h3>
                  }
                  key="f6"
                >
                  <Row>
                    <Col span={19}>
                      <Suspense fallback={<Spin>loading</Spin>}>
                        <StepTwo />
                      </Suspense>
                    </Col>
                    <HelpSidebarSection />
                  </Row>
                </TabPane>
                <TabPane
                  className="pt-5"
                  tab={
                    <h3 className="mb-0 tab-title">
                      {t("3. Jarohatlanganlar")}{" "}
                      <Tag className="f-code">F7</Tag>{" "}
                    </h3>
                  }
                  key="f7"
                >
                  <Row>
                    <Col span={19}>
                      <Suspense fallback={<Spin>loading</Spin>}>
                        <StepThree />
                      </Suspense>
                    </Col>
                    <HelpSidebarSection />
                  </Row>
                </TabPane>
                <TabPane
                  className="pt-5"
                  tab={
                    <h3 className="mb-0 tab-title">
                      {t("4. Ko'rilgan choralar")}{" "}
                      <Tag className="f-code">F8</Tag>{" "}
                    </h3>
                  }
                  key="f8"
                >
                  <Row>
                    <Col span={19}>
                      <Suspense fallback={<Spin>loading</Spin>}>
                        <StepFour />
                      </Suspense>
                    </Col>
                    <HelpSidebarSection />
                  </Row>
                </TabPane>
                {/* <TabPane className="pt-5" key="history" >
                  <Row>
                    <Col span={19}>
                      <Suspense fallback={<Spin>loading</Spin>}>
                        <HistoryOfChanges />
                      </Suspense>
                    </Col>
                    <HelpSidebarSection />
                  </Row>
                </TabPane> */}
              </Tabs>
            </div>
          </section>
          {mapVisible ? (
            <Suspense fallback={<div>Loading...</div>}>
              <Map
                visible={mapVisible}
                data={stepOne.location}
                handleModal={() => setMapVisible(false)}
              />
            </Suspense>
          ) : null}
        </S.NewCard>
      </NewCardContext.Provider>
    </HotKeys>
  );
}
