import { createGlobalStyle } from "styled-components";
import colors from "./colors";
import typography from "./typography";
import utils from "./utils";
import sidebar from "./sidebar";

export default createGlobalStyle`
  body {
    font-family: 'Roboto', "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    color: ${colors.text};
    letter-spacing: 0.3px;

    .ant-message {
      z-index: 9999;
    }
  }
  ${typography}
  ${sidebar}
  ${utils}
  .f-code {
    margin-left: 7px;
    padding-top: 3px;
    margin-right: 3px;
    display: none;
  }
  .ant-radio-button-wrapper-checked .f-code, .active  .f-code {
    background-color: rgba(255, 255, 255, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.31);
    color: rgb(255, 255, 255);
  }

  .info-badge {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  .clickable {
    cursor: pointer;

    & > svg {
      margin-left: 5px;
      position: relative;
      top: 2px;
    }
  }
  .sub-title {
    margin-bottom: 35px;
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 1;
  }
  .ant-input:focus, .ant-input:active {
    box-shadow: 0 0 0 2px rgba(66, 194, 147, 0.2);
  }
  .error-message {
    color: ${colors.danger};
    order: 2;
  }
  .input-controller {
    width: 100%;
    height: 60px;
    padding: 20px 20px 20px 12px;
    background: #FCFCFC;
    border: 1px solid ${colors.success}; 
    box-sizing: border-box;
    border-radius: 5px;
    outline: none;

    &:focus, &:active {
      border: 1px solid ${colors.success};
      background-color: #FFF;
      box-shadow: 0 0 0 2px rgba(66, 194, 147, 0.2);
    }
    &:disabled{
      border:1px solid #c3c5c9 !important;
    }

    &.textarea {
      min-height: 120px;
    }
  }

  .ant-picker-focused {
    background-color: #FFF;
  }
  .ant-select-item {
    min-height: 42px !important;
    line-height: 36px;
  }
  .custom-select-dropdown {
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }
    .empty-checkbox {
      width: 24px;
      height: 24px;
      border: 1px solid;
      border-radius: 6px;
    }
    .rc-virtual-list  {
      .checkicon {
        display: none;
      }
      .empty-checkbox {
        display: block;
        background-color: #FFF;
      }
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        .checkicon {
          display: block;
        }
        .empty-checkbox {
          display: none;
        }
      }
      .checkbox-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 7px;
        font-size: 24px;
      }
    }
  }
  .label-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 60px;
    flex-direction: column;
    text-align: right;
    
    label {
      padding-right: 10px;
      position: relative;
      
      .info-badge {
        position: absolute;
        right: -7px;
        top: 7px;
        transform: translateY(-5px);      }
    }
  }
  .error-message + .input-controller {
    border: 1px solid ${colors.danger};
  }
  .ant-checkbox-inner {
    width: 24px;
    height: 24px;

    &::after {
      width: 7px;
      height: 11px;
    }
  }
  button.default {
    border: 1px solid ${colors.text};

    &:hover {
      color: ${colors.success}
    }
  }
  button svg.has-icon {
    margin-left: 7px;
    font-size: 24px;
    vertical-align: bottom;
  }
  .ant-btn-primary {
    text-shadow: none;
    box-shadow: none;
  }
  button.ant-btn {
    height: 40px;
  }
  button.ant-btn-lg {
    height: 60px;
    padding: 20px 50px;
  }
  button.primary {
    padding: 20px 50px;
    color: #FFF;
    text-shadow: none;

    & > span {
      color: #FFF;
      text-shadow: none;
    }
  }
  .btn-outline-success {
    border-color: ${colors.success};
    color: ${colors.success};

    &:hover {
      background-color: ${colors.success};
      color: #FFF;
    }
  }
  .ant-collapse-header {
    font-family: 'Roboto-Medium';
  }
  
  .ant-collapse-content {
    border-top: 0;
  }
  .bg-grey {
    background-color: ${colors.grey}
  }
  .d-flex {
    display: flex;
    align-items: center;
  }

  .flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .swal2-title {
    font-family: "Roboto-Bold";
    color: ${colors.text};
  }
  .swal2-actions {
    flex-direction: row-reverse;

    button {
      height: 60px;
    }
    .swal2-styled.swal2-cancel{
      color: ${colors.text};
    }
    .swal2-confirm {
      flex: 1;
      color: ${colors.text};
    }
  }
  .ant-pagination-item-active {
    background-color: ${colors.success};
  }
  .ant-pagination-item-active a,
  .ant-pagination-item-active:focus a, 
  .ant-pagination-item-active:hover a {
    color: #fff;
  }
  .swal-danger {
    .swal2-confirm {
      flex: 1;
      color:#fff;
    }
  }
  .focusable:focus, .ant-input:focus, .ant-picker-focused, .ant-select-focused, .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-upload:focus {
    outline: 2px dashed #3987c4;
    outline-offset: 5px;
  }
  button.focusable:focus, button.focusable:hover {
    background-color: ${colors.success};
    color: #FFF;

    .hover-color-white path{
      stroke: #FFF;
    }
  }
  .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
    margin-bottom: 0;
  }
  .clear-btn {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 2px;
    background: #e7e7e7;
    color: #7e7e7e;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background: #7e7e7e;
      color:  #e7e7e7;
    }
  }
  .coordintaes-input {
    width: 100%; 
    max-width: 200px; 
    margin-left: 10px;
    border: 1px solid #999999;
    border-radius: 4px;
    padding: 3px 10px;
    height: 34px;
  }

  
`;
