/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  "1. Umumiy ma'lumotlar": "1. Умумий маълумотлар",
  "1.1dan - 1.6gacha ketma-ketlikda": "1.1dan - 1.6gacha ketma-ketlikda",
  "2. YTH ishtirokchilari": "2. ЙТҲ иштирокчилари",
  "YTH ishtirokchilari": "ЙТҲ иштирокчилари",
  "3. Jarohatlanganlar": "3. Жароҳатланганлар", //changed
  "4. Ko'rilgan choralar": "4. Кўрилган чоралар",
  "24 soatlik format": "24 соатлик формат",
  "Ag'darilish": "Ағдарилиш",
  "Aholi yashash punktini tanlang": "Аҳоли яшаш пунктини танланг",
  "Uzbekiston xududidan tashqarida": "Танланган координаталар Ўзбекистондан ташқарида бўлиши мумкин эмас",
  Aniqlash: "Аниқлаш",
  Asos: "Асос",
  Asosiy: "Асосий",
  "Asosni kiriting": "Асосни киритинг",
  "Axoli yashash punkti": "Ахоли яшаш пункти",
  "Bekor qilish": "Бекор қилиш",
  "Bu qismda YTHni chisqacha tasnifi to‘ldiriladi. To‘ldirish tartibi:":
    "Бу қисмда ЙТҲни чисқача таснифи тўлдирилади. Тўлдириш тартиби:",
  "Chet el fuqarosi": "Чет эл фуқароси",
  "Davlat raqami": "Давлат рақами",
  "Davlat raqami ko'rinadigan qilib old (chap-o'ng) va orqa (chap-o'ng) tomonlaridan suratga oling":
    "Давлат рақами кўринадиган қилиб олд (чап-ўнг) ва орқа (чап-ўнг) томонларидан суратга олинг",
  "Davlat raqamini kiriting": "Давлат рақамини киритинг",
  "Diqqat YTH sanasi 3 kundan avval": "Диққат ЙТҲ санаси 3 кундан аввал",
  "Diqqat YTH sanaga kelajak sana kiritildi":
    "ЙТҲ санага келажак сана киритилди",
  "Diqqat YTH oyga kelajak oy kiritildi":
    "Диққат ЙТҲ ойга келажак ой киритилди",
  "Diqqat YTH yilga kelajak yili kiritildi":
    "Диққат ЙТҲ йилга келажак йил киритилди",
  "F.I.SH": "Ф.И.Ш",
  "F.I.SH kiriting": "Ф.И.Ш  киритинг",
  Familyasi: "Фамиляси",
  "Fayl yuklash": "Файл юклаш",
  "Tex pasport raqami": "Тех паспорт рақами",
  "Tex pasport seriyasi": "Тех паспорт серийаси",
  "Tex pasport seriyasi va raqami": "Тех паспорт серийаси ва рақами",
  "Foydalanuvchi nomi yoki parol noto'g'ri":
    "Фойдаланувчи номи ёки пароль нотўғри",
  "Geo manzil": "Гео манзил",
  "Guvohnoma seriyasi va toifasi": "Гувоҳнома серийаси ва тоифаси",
  "Guvohnoma seriyasini kiriting": "Гувоҳнома серийасини киритинг",
  "Hafta kuni": "Ҳафта куни",
  "Hatolik yuz berdi": "Ҳатолик юз берди",
  "Haydovchi tomonidan YXQ buzulishi": "Ҳайдовчи томонидан ЙХҚ бузулиши",
  "Ishlab chiqarilgan yil": "Ишлаб чиқарилган йил",
  "Ishtirokchi qo'shish": "Иштирокчи қўшиш",
  "Ishtirokchi turi": "Иштирокчи тури",
  "Ishtirokchi turini tanlang": "Иштирокчи турини танланг",
  Ismi: "Исми",
  Jarohatlanganlar: "Жароҳатланганлар", //changed
  "Jarohatlanganlar umumiy soni": "Жароҳатланганлар умумий сони",
  Jinsi: "Жинси",
  "Jinsini tanlang": "Жинсини танланг",
  Keyingisi: "Кейингиси",
  "Ko'cha nomi": "Кўча номи",
  "Ko'cha nomini kiriting": "Кўча номини киритинг",
  "Ko'rilgan choralar": "Кўрилган чоралар",
  Kun: "Кун",
  "Kunning vaqti": "Куннинг вақти", //changed
  "Kunning vaqtini": "Kunning vaqtini", // ?
  "Kunning vaqtini tanlang": "Куннинг вақтини танланг",
  "Ma'lumot muvaffaqiyatli yangilandi": "Маълумот муваффақиятли янгиланди ",
  "Ma'lumot muvaffaqiyatli yaratildi": "Маълумот муваффақиятли яратилди",
  "Ma'lumot yo'q": "Маълумот йўқ",
  "Manzilni kiriting": "Манзилни киритинг",
  Marka: "Марка",
  Model: "Модел",
  "Muvaffaqiyatli tizimga kirish!": "Муваффақиятли тизимга кириш!",
  "O'chirish": "Ўчириш",
  "O'zbekcha": "O'zbekcha", // ?
  "O'zgarishlar tarixi": "Ўзгаришлар тарихи",
  "Ob-havo sharoiti": "Об-ҳаво шароити",
  "Piyodani urib yuborish": "Piyodani urib yuborish", // ?
  "Qatnov qismining xolati": "Қатнов қисмининг холати",
  "Qaysi modda bo'yicha": "Қайси модда бўйича",
  "Qayta ochish": "Қайта очиш",
  Qidiruv: "Қидирув",
  "Qora qalin bilan belgilaganlar to‘ldirilishi shart":
    "Qora qalin bilan belgilaganlar to‘ldirilishi shart", //?
  Rangi: "Ранги",
  "Ro'yhatga olingan(xududiy tegishligi)":
    "Рўйҳатга олинган(худудий тегишлиги)",
  "Ruldagi vaqti": "Рулдаги вақти",
  Ruscha: "Русча",
  Sana: "Сана",
  "Shahar/Tuman": "Шаҳар/Туман",
  "Shahar/tumanni tanlang": "Шаҳар/туманни танланг",
  Sharifi: "Шарифи",
  "Shaxsi aniqlanmagan": "Шахси аниқланмаган",
  Soat: "Соат",
  "Suratga 4 burchakdan oling": "Суратга 4 бурчакдан олинг",
  "Suratga olish burchagi 45 darajada bo'lishi kerak":
    "Суратга олиш бурчаги 45 даражада бўлиши керак",
  Tajribasi: "Тажрибаси",
  "Talab qilingan ma'lumotlarni kiriting":
    "Талаб қилинган маълумотларни киритинг",
  Tanlash: "Танлаш",
  "Tartib raqami(jinoiy/mamutiy ish nomeri yoki rad etish raqami)":
    "Тартиб рақами(жиноий/мамутий иш номери ёки рад этиш рақами)",
  Tasdiqlash: "Тасдиқлаш",
  Tegishliligi: "Тегишлилиги",
  "Tex pasport seriyasi va raqamini kiriting":
    "Тех паспорт серийаси ва рақамини киритинг",
  "Texnik ko'rikdan o'tgan sana": "Техник кўрикдан ўтган сана",
  "Tirkama mavjud": "Тиркама мавжуд",
  "To'ldirilmoqda": "Тўлдирилмоқда",
  "To'qnashuv": "Тўқнашув", // ?
  "To'siqqa urilish": "Тўсиққа урилиш", //?
  "Transport turi": "Транспорт тури",
  "TRANSPORT VOSITASI haqida ma'lumotlar":
    "ТРАНСПОРТ ВОСИТАСИ ҳақида маълумотлар",
  "Transportni aniqlash": "Транспортни аниқлаш",
  "Tug'ilgan yili": "Туғилган йили",
  Tun: "Тун",
  "Turgan TV-ga urilish": "Турган ТВ-га урилиш",
  TV: "ТВ",
  "TV kimga tegishli": "ТВ кимга тегишли",
  "TV texnik nosozlik turlarini tanlang":
    "ТВ техник носозлик турларини танланг",
  "TV texnik nosozliklari": "ТВ техник носозликлари",
  "Umumiy ma'lumotlar": "Умумий маълумотлар",
  "Ushbu YTH ishtirokchisini o'chirmoqchimisiz?":
    "Ушбу ЙТҲ иштирокчисини ўчирмоқчимисиз?",
  "Ushbu YTH yo'lovchisini o'chirmoqchimisiz?":
    "Ушбу ЙТҲ йўловчисини ўчирмоқчимисиз?",
  "Velosipedchini urib yuborish": "Velosipedchini urib yuborish", //?
  Viloyat: "Вилоят",
  "Viloyatni tanlang": "Вилоятни танланг",
  "Xalok bo'lganlarning umumiy soni": "Халок бўлганларнинг умумий сони",
  "Xaritadan tanlash": "Харитадан танлаш",
  "Xatolik yuz berdi": "Хатолик юз берди",
  "Xavfsizlik kamari, motoshlem": "Хавфсизлик камари, мотошлем",
  Haydovchi: "Ҳайдовчи", //changed
  "Haydovchini aniqlash": "Ҳайдовчини аниқлаш", //changed
  "Hisobdan chiqarish": "Ҳисобдан чиқариш", //changed
  Saqlash: "Сақлаш",
  YTH: "ЙТҲ", //changed
  "Yashash joyi va manzili": "Яшаш жойи ва манзили",
  yil: "йил",
  "Yillik tajribasini kiriting": "Йиллик тажрибасини киритинг",
  "Yo'l sharoitlari": "Йўл шароитлари",
  "Yo'l/ko'cha ahamayatini tanlang": "Йўл/кўча аҳамаятини танланг",
  "Yo'l/ko'chaning ahamyati": "Йўл/кўчанинг аҳамяти",
  "Yo'lning qaysi KMda": "Йўлнинг қайси КМда", //changed
  "Yo'lning qismi": "Йўлнинг қисми",
  "Yo'lning qoplama turi": "Йўлнинг қоплама тури",
  "Yo'lovchi": "Йўловчи",
  "Yo'lovchi tashuvchi TV": "Йўловчи ташувчи ТВ",
  "YO'LOVCHILAR haqida ma'lumotlar": "ЙЎЛОВЧИЛАР ҳақида маълумотлар",
  Yordam: "Ёрдам",
  Yoritilganlik: "Ёритилганлик",
  yosh: "ёш",
  "YTH joyida transport vositasini suratga olish":
    "ЙТҲ жойида транспорт воситасини суратга олиш",
  "YTH geo manzilni kiriting": "ЙТҲ гео манзилни киритинг",
  "YTH joyidagi video va rasmlar": "ЙТҲ жойидаги видео ва расмлар",
  "YTH kartochkasi": "ЙТҲ карточкаси",
  "YTH keltirib chiqaruvchi holat": "ЙТҲ келтириб чиқарувчи ҳолат",
  "YTH keltirib chiqaruvchi holatlar": "ЙТҲ келтириб чиқарувчи ҳолатлар",
  "YTHdan keyin avtoulovni qanday qilib to'g'ri suratga olish kerak":
    "ЙТҲдан кейин автоуловни қандай қилиб тўғри суратга олиш керак",
  "YTH qisqa bayoni": "ЙТҲ қисқа баёни",
  "YTH qisqacha tavsifi": "ЙТҲ қисқача тавсифи",
  "YTH raqamini kiriting": "ЙТҲ рақамини киритинг",
  "YTH ro'yhatga olingan raqam": "ЙТҲ рўйҳатга олинган рақам",
  "YTH sodir bo'lgan joy": "ЙТҲ содир бўлган жой",
  "YTH sodir bo'lgan vaqt": "ЙТҲ содир бўлган вақт",
  //Text changed
  "YTH statistikasi": "ЙТҲ статистикаси",
  "YTH tavsifi": "ЙТҲ тавсифи",
  "YTH turi": "ЙТҲ тури",
  "YTH turini tanlang": "ЙТҲ турини танланг", //changed
  "YTH turi va raqamini kiriting": "YTH turi va raqamini kiriting", //?
  "YTH haqida ma'lumot qo'shing": "ЙТҲ ҳақида маълумот қўшинг",
  "YTH ishini olib borgan xodim FISH, unvoni":
    "ЙТҲ ишини олиб борган ходим ФИШ, унвони",
  "YTH ishini olib borgan xodim FISH, unvoni kiriting":
    "ЙТҲ ишини олиб борган ходим ФИШ, унвони ҳақида маълумот киритинг",
  "YTH ishtirokchisini o'chirmoqchimisiz, ushbu ishtirokchi uchun kiritilgan barcha ma'lumotlar o'chib ketadi":
    "ЙТҲ иштирокчисини ўчирмоқчимисиз, ушбу иштирокчи учун киритилган барча маълумотлар ўчиб кетади",
  "YTH joyidan yashiringan": "ЙТҲ жойидан яширинган",
  "YTH yo'lovchisini o'chirmoqchimisiz, ushbu yo'lovchi uchun kiritilgan barcha ma'lumotlar o'chib ketadi":
    "ЙТҲ йўловчисини ўчирмоқчимисиз, ушбу йўловчи учун киритилган барча маълумотлар ўчиб кетади",
  "YXQ buzulishi": "ЙХҚ бузулиши",
  Узбекча: "Узбекча",
  "YTH ID raqami": "ЙТҲ ИД рақами", //changed
  "YTH hisob varaqasi": "ЙТҲ хисоб варақаси",
  Holati: "Ҳолати",
  Tuman: "Туман",
  "Xalok bo'lganlar": "Халок бўлганлар",
  "YTH Kartochka yaratish": "ЙТҲ Карточка яратиш",
  "YTH kartochkalari": "ЙТҲ карточкалари",
  Yopilgan: "Ёпилган",
  "O'zgartirilgan": "Ўзгартирилган",
  "Hisobdan chiqarilgan": "Ҳисобдан чиқарилган", //changed
  "Kech to'ldirilmoqda": "Кеч тўлдирилмоқда", //changed
  Oy: "Ой",
  "O'lim bilan bog'lik": "Ўлим билан боғлик",
  Jarohatlanish: "Жароҳатланиш", //changed
  "Sog'liqqa zarar yetmagan": "Соғлиққа зарар етмаган",
  Tahrirlash: "Таҳрирлаш",
  "Ma'lumot muvaffaqiyatli o'chirildi": "Маълумот муваффақиятли ўчирилди",
  "Ma'lumotnomalar": "Маълумотномалар",
  "Ma'lumotnoma nomi": "Маълумотнома номи",
  "Ma'lumot qo'shish": "Маълумот қўшиш",
  Faol: "Фаол",
  Nofaol: "Нофаол",
  "Davlatni tanlang": "Давлатни танланг",
  "tomonidan YXQ buzulishi": "томонидан ЙХҚ бузилиши",
  Toifa: "Тоифа",
  "Boshqa identifaktor": "Бошқа идентификатор",
  "Chet elda ro'yhatga olingan": "Чет элда рўйҳатга олинган",
  "Tumanni tanlang": "Туманни танланг",
  "Yo'lovchi qo'shish": "Йўловчи қўшиш",
  Vazifalar: "Вазифалар",
  Yaratish: "Яратиш",
  "Tanlangan ma'lumotlar soni:": "Танланган маълумотлар сони",
  Chiqish: "Чиқиш",
  "O'chirmoqchimisiz?": "Ўчирмоқчимисиз?",
  passenger: "Йўловчи",
  driver: "ТВ",
  pedestrian: "Пиёда",
  bicycle: "Велосипедчи",
  carriage: "От-аравачи",
  scooter: "Скутерчи",
  "Texnik ko'rikdan o'tgan": "Техник кўрикдан ўтган",
  "Yo'q": "Йўқ",
  "Ushbu so'rov uchun hech qanday ma'lumot topilmadi.":
    "Ушбу сўров учун ҳеч қандай маълумот топилмади",
  "YTH turi bo'yicha": "ЙТҲ тури бўйича",
  "YTH qisqacha tavsifi (bayoni)": "ЙТҲ қисқача тавсифи (баёни)",
  "HAYDOVCHI haqida ma'lumot": "ҲАЙДОВЧИ ҳақида маълумот",
  "PIYODA haqida ma'lumot": "ПИЁДА ҳақида маълумот",
  "VELOSIPEDCHI haqida ma'lumot": "ВEЛОСИПEДЧИ ҳақида маълумот",
  "OT-ARAVACHI haqida ma'lumot": "ОТ-АРАВАЧИ ҳақида маълумот",
  "SKUTERCHI haqida ma'lumot": "СКУТEРЧИ ҳақида маълумот",
  "Qo'shimcha ma'lumot": "Қўшимча маълумот",
  Shifoxona: "Шифохона",
  "Bu yerda YTH kartochkasi yaratilgan sanadan boshlab barcha o'zgarishlar qayd etiladi.":
    "Бу ерда ЙТҲ карточкаси яратилган санадан бошлаб барча ўзгаришлар қайд этилади.",
  "Ushbu YTH kartochkasini hisobdan chiqarmoqchimisiz?":
    "Ушбу ЙТҲ карточкасини ҳисобдан чиқармоқчимисиз?",
  "Hisobdan chiqarilgan kartochkalarni qayta tiklash mumkin bo'lmaydi. Davom ettirishni xoxlaysizmi?":
    "Ҳисобдан чиқарилган карточкаларни қайта тиклаш мумкин бўлмайди. Давом эттиришни хохлайсизми?",
  "Tizimga kirish": "Тизимга кириш",
  "Parolingizni kiriting!": "Паролингизни киритинг!",
  "Emailingizni kiriting!": "Email киритинг!",
  "Loginingizni kiriting!": "Email киритинг!",
  "Xodimlar uchun kirish": "Ходимлар учун кириш",
  "YTH hisobga olish tizimi": "ЙТҲ ҳисобга олиш тизими",
  Parol: "Парол",
  Login: "Логин",
  "Ko'cha nomi": "Кўча номи",
  "Qatorlar soni": "Қаторлар сони",
  "Jarohatlanganlar haqida ma'lumot": "Жароҳатланганлар ҳақида маълумот",
  "YTH kartochka yaratilmadi. Yaratish uchun YTH turini, sodir bo'lgan sana va vaqtni kirish":
    "ЙТҲ карточка яратилмади. Яратиш учун ЙТҲ турини, содир бўлган сана, вақт, вилоят, туманни ва манзил киритиш керак.",
  "YTH kartochka yaratilmadi. Yaratish uchun Piyodaning F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting":
    "ЙТҲ карточка яратилмади. Яратиш учун Пиёданинг Ф.И.Ш, туғилган йили, жинси ва Яшаш манзилини киритинг",
  "YTH kartochka yaratilmadi. Yaratish uchun Transport vositasining marka, modul, transport turi va rangini kiriting":
    "ЙТҲ карточка яратилмади. Яратиш учун Транспорт воситасининг марка, модул, транспорт тури ва рангини киритинг",
  "YTH kartochka yaratilmadi. Yaratish uchun Velosipedchining F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting":
    "ЙТҲ карточка яратилмади. Яратиш учун Велосипедчининг Ф.И.Ш, туғилган йили, жинси ва Яшаш манзилини киритинг",
  "YTH kartochka yaratilmadi. Yaratish uchun Ot-Aravachining F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting":
    "ЙТҲ карточка яратилмади. Яратиш учун От-Aравачининг Ф.И.Ш, туғилган йили, жинси ва Яшаш манзилини киритинг",
  "YTH kartochka yaratilmadi. Yaratish uchun Skuterterchining F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting":
    "ЙТҲ карточка яратилмади. Яратиш учун Скутертерчининг Ф.И.Ш, туғилган йили, жинси ва Яшаш манзилини киритинг",
  "YTH kartochka yaratilmadi. Yaratish uchun Haydovchining F.I.Sh, tug'ilgan yili, jinsi va Yashash manzilini kiriting":
    "ЙТҲ карточка яратилмади. Яратиш учун Ҳайдовчининг Ф.И.Ш, туғилган йили, жинси ва Яшаш манзилини киритинг",
  Yil: "Йил",
  Sahifa: "Саҳифа",
  Shaxsiy: "Шахсий",
  "Shaxsiy ma'lumotlar": "Шахсий маълумотлар",
  "Yangilanadigan ma'lumot yo'q.": "Янгиланадиган маълумот йўқ.",
  "Ishonchingiz komilmi?": "Ишончингиз комилми?",
  "Haqiqatdanham parol yoki loginingizni o'zgartirmoqchimisiz?":
    "Ҳақиқатданҳам пароль ёки логинингизни ўзгартирмоқчимисиз?",
  Foydalanuvchilar: "Фойдаланувчилар",
  "Foydalanuvchi qo'shish": "Фойдаланувчи қўшиш",
  "Moddalarni kiriting": "Моддаларни киритинг",
  "Kodeks turini tanlang": "Кодекс турини танланг",
  "Tartib raqami": "Тартиб рақами",
  male: "Эркак",
  female: "Аёл",
  night: "Тун",
  day: "Кун",
  healthy: "Соғлом",
  died: "Вафот этган",
  injured: "Жароҳатланган",
  administrative: "Маъмурий",
  criminal: "Жиноий",

  Unvoni: "Унвони",
  Guruhlar: "Гуруҳлар",
  "Foydalanuvchi muvaffaqiyatli yaratildi":
    "Фойдаланувчи муваффақиятли яратилди",
  "Ism majburiy": "Исм мажбурий",
  "Parol majburiy": "Парол мажбурий",
  "Login majburiy": "Логин мажбурий",
  "Haqiqatdanham guruhni o'chirmoqchimisiz?":
    "Ҳақиқатданҳам гуруҳни ўчирмоқчимисиз?",
  "Haqiqatdanham foydalanuvchini o'chirmoqchimisiz?":
    "Ҳақиқатданҳам фойдаланувчини ўчирмоқчимисиз?",
  "Guruh qo'shish": "Гуруҳ қўшиш",
  "Yangi guruh": "Янги гуруҳ",
  "Yangi guruh nomi": "Янги гуруҳ номи",
  "Ma'lumot kiritish majburiy": "Маълумот киритиш мажбурий",
  "Parolni tasdiqlash": "Паролни тасдиқлаш",
  "Iltimos Parolni tasdiqlang": "Илтимос Паролни тасдиқланг",
  "Siz kiritgan ikkita parol mos emas!": "Сиз киритган иккита парол мос емас!",
  "O'chirish/Hisobdan chiqarish": "Ўчириш/Ҳисобдан чиқариш",
  "O'zgartirilgan ma'lumotlar": "Ўзгартирилган маълумотлар",
  "O'chirilgan": "Ўчирилган",
  "O'chirilgan ma'lumotlar": "Ўчирилган маълумотлар",
  "Qo'shilgan ma'lumotlar": "Қўшилган маълумотлар",

  tech_passport_series: "Техпаспорт серияси",
  tech_passport_number: "Техпаспорт рақами",
  plate_number: "Давлат рақам белгиси (ДРБ)",
  another_identifier: "Бошқа идентификатор",
  identifier_name: "Идентификатор номи",
  identifier_number: "Идентификатор рақами",
  vehicle_address: "Транспорт воситаси рўйхатга олинган манзили",
  is_vehicle_foreigner: "Транспорт воситаси чет елда рўйхатга олинган",
  is_passenger_transportation: "Йўловчи ташувчи транспорт",
  has_trailer: "Тиркама мавжуд",
  year_manufacture: "Ишлаб чиқарилган йили",
  belong_to: "Мулкдор",
  inspection_date: "Техник кўрикдан ўткан сана",
  vehicle_type: "Транспорт тури",
  vehicle_brand: "Транспорт маркаси",
  vehicle_model: "Транспорт русуми",
  vehicle_color: "Транспорт ранги",
  vehicle_region: "Транспорт рўйхатга олинган вилоят",
  vehicle_district: "Транспорт рўйхатга олинган туман",
  vehicle_country: "Транспорт рўйхатга олинган давлат",
  belong_type: "Мулкдор тури",
  technical_issues: "Техник носозликлар",
  is_deleted: "Ҳисобдан чиқарилган",
  last_name: "Фамилияси",
  first_name: "Исми",
  middle_name: "Шарифи",
  is_hidden: "ЙТҲ жойидан яширинган",
  is_identified: "Шахси аниқланмаган",
  age: "Ёши",
  gender_type: "Жинси",
  address: "Манзили",
  is_foreigner: "Чет ел фуқароси",
  certificate_series: "Ҳайдовчилик гувоҳнома рақами",
  driver_experience: "Ҳайдовчи тажрибаси",
  driving_time: "Рўлдаги вақти",
  safety_belt: "Хавфсизлик камари, мотошлем",
  health_condition: "Ҳолати",
  add_information: "Қўшимча маълумотлар",
  region: "Вилоят",
  district: "Туман",
  vehicle: "Транспорт воситаси",
  country: "Давлат",
  licence_categories: "Гувоҳнома категорияси",
  hospital: "Касалхона",
  is_late: "Кечикмоқда",
  status: "Статус",
  card_number: "Карточка рақами",
  accident_number: "ЙТҲ рақами",
  date_accident: "ЙТҲ санаси",
  location: "ЙТҲ содир бўлган жой",
  street_name: "Кўчанинг номи",
  distance_from: "Йўлнинг қайси КМда",
  description: "ЙТҲ қисқа баёни",
  part_of_day: "Куннинг вақти",
  officer: "ЙТҲ ишини олиб борган ходим",
  article: "Модда",
  order_number: "Тартиб рақами",
  codex: "Кодекс",
  accident_type: "ЙТҲ тури",
  residence_type: "Ахоли яшаш жойи тури",
  street_significance: "Йўл/кўчанинг аҳамияти",
  road_surface: "Йo'lning qoplama turi",
  road_condition: "Қатнов қисмининг ҳолати",
  weather_condition: "Об-ҳаво шароити",
  illumination: "Ёритилганлик",
  road_part: "Йўлнинг қисми",
  taken_measures: "Кўрилган чоралар",
  accident_causal: "ЙТҲ келтириб чиқарувчи ҳолатлар",
  violation: "ЙХҚ бузулиши",
  organization: "Ташкилотлар",
  "Ichki foydalanuvchi": "Ички фойдаланувчи",
  "Tashqi foydalanuvchi": "Ташқи фойдаланувчи",
  "Guruh nomi": "Гуруҳ номи",
  "Ro'yhat": "Рўйҳат",
  "O'qish": "Ўқиш",
  "O'zgartirish": "Ўзгартириш",
  "Tashqariga API": "Ташқарига API",
  "Otasining Ismi": "Отасининг Исми",
  "Haydovchining manzili bilan bir xil": "Ҳайдовчининг манзили билан бир хил",
  "Haydovchining ismi bilan bir xil": "Ҳайдовчининг исми билан бир хил",
  "Keyingi texnik ko'rik sanasi": "Кейинги техник кўрик санаси",
  "O'tgan": "Ўтган",
  "O'tmagan": "Ўтмаган",
  "Texnik ko'rik sanasi": "Техник кўрик санаси",
  "O'tgan sana": "Ўтган сана",
  "Keyingi sana": "Кейинги сана",
  // ============= INSPECTION DATE ===============
  authority: "authority",
  dateInpsection: "Техник кўрикдан ўтган сана",
  dateNextInpsection: "Кейинги сана",
  resultInpsection: "Техник кўрикдан ўтганлиги",
  specialMarks: "Aлоҳида белгилар",
  pComments: "Изоҳлар",
  pDivision: "ЙҲХ бўлими",
  pEmptyWeight: "Юксиз вазни",
  pFuelType: "Ёнилғи тури",
  pFullWeight: "Тўла вазни",
  pKuzov: "Кузов рақами",
  pModel: "Русуми",
  pMotor: "Мотор рақами",
  pPlateNumber: "Давлат рақам белгиси (ДРБ)",
  pRegistrationDate: "Қайд етилган сана",
  pSeats: "Ўриндиқлар сони",
  pStands: "Тик турадиган жойлар сони",
  pVehicleColor: "Ранги",
  pVehicleId: "pVehicleId",
  pVehicleType: "Транспорт тури",
  pYear: "Ишлаб чиқарилган йили",
  // Handbook field names
  technicalissues: "Техник носозликлар",
  vehicletype: "Транспорт тури",
  vehiclebrand: "Транспорт маркаси",
  vehiclemodel: "Транспорт русуми",
  vehiclecolor: "Транспорт ранги",
  vehiclecolour: "Транспорт ранги",
  vehiclemark: "Транспорт маркаси",
  accidentcausal: "ЙТҲ келтириб чиқарувчи ҳолатлар",
  accidenttype: "ЙТҲ тури",
  residencetype: "Aхоли яшаш жойи тури",
  streetsignificance: "Йўл/кўчанинг аҳамияти",
  roadsurface: "Йўлнинг қоплама тури",
  roadcondition: "Қатнов қисмининг ҳолати",
  weathercondition: "Об-ҳаво шароити",
  roadpart: "Йўлнинг қисми",
  takenmeasures: "Кўрилган чоралар",
  partroad: "Йўлнинг қисми",
  roadtypeaccident: "ЙТҲ тури",
  licencecategory: "Гувоҳнома категорияси",
  belongtype: "Мулкдор тури",
  typeresidence: "Aхоли яшаш жойи тури",
  Nomi: "Исм",
  Kartochka: "Карточка",
  Guruh: "Гуруҳ",
  Foydalanuvchi: "Фойдаланувчи",
  "Haydovchilik guvohnomasi va toifasi": "Ҳайдовчилик гувоҳномаси ва тоифаси",
  "Guvohnoma seriyasi va raqamini kiriting":
    "Гувоҳнома церийаси ва рақамини киритинг",
  Xodim: "Ходим",
  "Cheklangan foydalanuvchi": "Чекланган фойдаланувчи",
  "O'zgarishlarni saqlash": "Ўзгаришларни сақлаш",
  "Kartochkadagi o'zgarishlarni saqlab qo'yish":
    "Карточкадаги ўзгаришларни сақлаб қўйиш",
  "Yo'l": "Йўл",
  "Yo'lni tanlang": "Йўлни танланг",
  Kod: "Код",

  "Hudud bo'yicha": "Ҳудуд бўйича",
  "Ishtirokchi holati bo'yicha": "Иштирокчи ҳолати бўйича",
  Tozalash: "Тозалаш",
  "Qo'llash": "Қўллаш",
  "Sana bo'yicha": "Сана бўйича",
  "Tashkilotni tanlang": "Ташкилотни танланг",
  "Tashkilot nomi": "Ташкилот номи",
  "Eski tizimdagi ma'lumotni yangi tizimga mos ravishda kiriting":
    "Эски тизимдаги маълумотни янги тизимга мос равишда киритинг",
  "Texnik ko'rikdan o'tmagan": "Техник кўрикдан ўтмаган",
  "Qidiruv bo'yicha ma'lumot topilmadi": "Қидирув бўйича маълумот топилмади",
  "Ma'lumot topilmadi": "Маълумот топилмади",
  "Server bilan aloqa uzuldi.": "Сервер билан алоқа узулди.",
  "Internet bilan aloqa uzuldi. Birozdan so'ng urunib ko'ring":
    "Интернет билан алоқа узулди. Бироздан сўнг уруниб кўринг",
  "Sizda bu amalni oshirish uchun ruhsat yo'q":
    "Сизда бу амални ошириш учун руҳсат йўқ",
  "Hisobot yaratishda xatolik yuz berdi.": "Ҳисобот яратишда хатолик юз берди.",
  Hisobotlar: "Ҳисоботлар",
  Xarita: "Харита",
  Export: "Экспорт",

  "YTH holatlari bo'yicha": "ЙТҲ холатлари бўйича",
  Geolokatsiya: "Геолокация",
  "Lokatsiya mavjud emas": "Локатсия мавжуд емас ",
  Sanadan: "Санадан",
  Sanagacha: "Санагача",
  Soatdan: "Соатдан",
  Soatgacha: "Соатгача",
  "YTH turlari bo'yicha": "ЙТҲ турлари бўйича",
  "YTH ishtirokchi turlari bo'yicha": "ЙТҲ иштирокчи турлари бўйича",
  "Yo'l ahamiyati bo'yicha": "Йўл аҳамияти бўйича",

  cardhistory: "Ўзгаришлар тарихи",
};
