import { Button } from 'antd';
import React from 'react';
import { BsFileEarmarkPlus } from 'react-icons/bs';
import { BiPlusCircle } from 'react-icons/bi';
import S from './style';
import PropType from 'prop-types';

export default function EmptyCardData(props) {
  const { title, text, onClick, buttonTitle, customButton } = props;
  return (
    <S.EmptyCard>
      <BsFileEarmarkPlus size="36" className="has-icon" />
      <h3>{title}</h3>
      <p>{text}</p>
      {props.children}
      {
        !customButton && <Button onClick={onClick} className="has-icon">{buttonTitle} <BiPlusCircle className="has-icon" /></Button>
      }
    </S.EmptyCard>
  )
}

EmptyCardData.defaultProps = {
  title: '',
  onClick: () => undefined,
  text: '',
  buttonTitle: '',
  customButton: false,
  children: null,
}

EmptyCardData.propTypes = {
  title: PropType.string,
  text: PropType.string,
  onClick: PropType.func,
  buttonTitle: PropType.string,
  customButton: PropType.bool,
  children: PropType.any,
}
