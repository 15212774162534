import moment from 'moment';
import React from 'react';
import S from '../../styles/statistics';
import { t } from '../../utils';

export default function Summary({
  died = 0,
  injured = 0,
  startDate = moment().startOf('month'),
  endDate = moment(),
  total = 0
}) {

  return (
    <S.Summary className="my-5 b-1 p-4 b-radius">
      <d className="d-flex header">
        <h4>{moment(startDate).format('MMM DD, YYYY')} - {moment(endDate).format('MMM DD, YYYY')}</h4>
        <h4>{t("YTHlar soni")} - {total}</h4>
      </d>
      <div className="card-wrapper">
        <div className="card">
          <h4>{t("Xalok bo'lganlar")} - {died}</h4>
          {/* <p>{t("Haydovchi")} - 2</p>
          <p>{t("passenger")} - 2</p>
          <p>{t("pedestrian")} - 2</p>
          <p>{t("bicycle")} - 2</p>
          <p>{t("scooter")} - 2</p> */}
        </div>
        <div className="card">
          <h4>{t("Jarohatlanganlar")} - {injured}</h4>
          {/* <p>{t("Haydovchi")} - 2</p>
          <p>{t("passenger")} - 2</p>
          <p>{t("pedestrian")} - 2</p>
          <p>{t("bicycle")} - 2</p>
          <p>{t("scooter")} - 2</p> */}
        </div>
      </div>
    </S.Summary>
  )
}
