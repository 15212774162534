import XLSX from 'xlsx';
import moment from 'moment';

export const exportToExel = ({ title = 'report', data = [] }) => {
  const fileName = `${title.toLowerCase().replace(/\s/g, '-')}-${moment().format('YYYY-MM-DD')}`;

  let jsonToXls = XLSX.utils.json_to_sheet(data);
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, jsonToXls, fileName);
  XLSX.writeFile(wb, fileName + '.xlsx');
}
export const exportTableToExel = ({ title = 'report', table }) => {
  const fileName = `${title.toLowerCase().replace(/\s/g, '-')}`.slice(0, 30);

  let jsonToXls = XLSX.utils.table_to_sheet(table);
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, jsonToXls, fileName);
  XLSX.writeFile(wb, fileName + '.xlsx');
}