import styled from 'styled-components';
import colors from './colors';

const S = {};

S.Main = styled.div`
  margin-bottom: 15px;
  margin-left: auto;
  
  table, tr, td, th {
    border: 1px solid;
    border-collapse: collapse;
  }
  .report-btn {
    height: 32px;
  }
  .report-item {
    display: flex;
    align-items: center;
    border: 1px solid ${colors.border};
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 15px;

    h3  {
      margin: 0 20px;
      width: 50%;
      text-align: left;
    }
    button:first-of-type {
      margin-left: auto;
      margin-right: auto;
    }

    button:last-of-type {
      background-color: transparent;
      border: none;
      font-size: 20px;
    }
  }
`;

S.Table = styled.div`

  .hidden-table {
    height: 0;
    width: 0;
    overflow: hidden;
    opacity: 0;
  }
  
`;

S.Filter = styled.div`
  margin-bottom: 15px;
  .filter__wrapper {
    border: 1px solid #c2c5c9;
    padding: 20px;
    margin: 5px 0 20px;
    border-radius: 5px;
    background: #fdfdfd;
  }

  .filter__toggler {
    text-align: right;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      transition: .3s;

      &.active, &:hover {
        background-color: #333;
        color: #fdfdfd;
        border: 1px solid #333;
        transition: .3s;
      }
    }
  }

  .filter__inputs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
    gap: 20px;
  }

  .filter__btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
`

export default S;