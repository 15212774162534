import * as types from '../actionTypes';

const newPassenger = {
  type: 'passenger'
};

const actions = {};
actions.stepOne = (data) => ({ type: types.CARD_EDIT_STEP_ONE, data });
actions.setUpdatedCardField = (data) => ({ type: types.CARD_EDIT_UPDATED_FIELDS, data });
actions.stepTwo = (data) => ({ type: types.CARD_EDIT_STEP_TWO, data });
actions.stepFour = (data) => ({ type: types.CARD_EDIT_STEP_FOUR, data });

actions.stepTwoAddParticipant = ({ type, key }) => {
  if (type !== 'driver') {
    return ({
      type: types.CARD_EDIT_STEP_TWO_ADD_PARTICIPANT,
      data: { type, key },
      key,
    })
  }
  return ({
    type: types.CARD_EDIT_STEP_TWO_ADD_PARTICIPANT,
    data: { participants: [{ type: 'driver', key }], type, key },
    participantType: 'driver',
    key,
  })
};

actions.stepTwoEditParticipant = ({ data, key, participantType }) => ({ type: types.CARD_EDIT_STEP_TWO_EDIT_PARTICIPANT, data, key, participantType });
actions.stepTwoDeleteParticipant = ({ key, type }) => ({ type: types.CARD_EDIT_STEP_TWO_DELETE_PARTICIPANT, data: { key, type } });

actions.stepTwoAddPassenger = ({ parentKey, key }) => ({ type: types.CARD_EDIT_STEP_TWO_ADD_PASSENGER, data: { ...newPassenger, key, }, key, parentKey });
actions.stepTwoEditPassenger = ({ passengers, parentKey }) => ({ type: types.CARD_EDIT_STEP_TWO_EDIT_PASSENGER, passengers, parentKey });
actions.stepTwoDeletePassenger = ({ parentKey, key, passengers }) => ({ type: types.CARD_EDIT_STEP_TWO_DELETE_PASSENGER, key, parentKey, passengers });

export default actions;