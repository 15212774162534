/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Layout, Menu, Divider } from 'antd';
import { isReadOnlyUser, t } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, NavLink } from 'react-router-dom';
import { BiLogOut, BiBookAlt, BiGroup, BiGridAlt } from 'react-icons/bi';
import actions from '../../api/handbooks';
import handbookActions from '../../store/handbooks/actions';
import moment from 'moment';
import Swal from 'sweetalert2';
import Language from './Language';

import imageProfile from '../../assets/images/profile.svg';
import { ReactComponent as IconHome } from '../../assets/images/sidebar/home.svg';
import { ReactComponent as IconExplore } from '../../assets/images/sidebar/explore.svg';
import { ReactComponent as IconSearch } from '../../assets/images/sidebar/search.svg';
import { ReactComponent as IconChart } from '../../assets/images/sidebar/chart.svg';
import { logoutAction, updateUser } from '../../store/auth/actions';
import { getCurrentUser } from '../../api/auth';
import colors from '../../styles/colors';

const { Content, Sider } = Layout;
function LayoutPage(props) {
  const dispatch = useDispatch();
  const { token, user = {} } = useSelector(state => state.auth || {});
  const handbooks = useSelector(state => state.handbooks || {});
  const location = useLocation();
  const currentActiveNavLink = location.pathname.split('/').filter(i => i)[0];

  const handleLogOut = async () => {
    Swal.fire({
      title: t("Tizimdan chiqish"),
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      text: t("Tizimdan chiqishni tasdiqlaysizmi?"),
      cancelButtonColor: '#E7E9EB',
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: 'swal-danger',
    }).then(async ({ value }) => {
      if (value) {
        dispatch(logoutAction());
      }
    })

  };

  const fetchMyAccount = async () => {
    const data = await getCurrentUser();
    if (!data?.error) {
      return dispatch(updateUser(data));
    } else {
      console.error(data)
    }
  }

  useEffect(() => {
    fetchMyAccount();
  }, [token]);

  useEffect(() => {
    (async () => {
      const data = await actions.fetch();
      dispatch(handbookActions.setHandbooks(data));
    })()
  }, []);

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="80"
        className="custom-sidebar br-1"
        width="250"
      >
        <div className="sidebar-inner-wrapper">
          <a href="/profile" className="profile">
            <div className="profile-image mb-2">
              <img src={imageProfile} alt="Profile" />
            </div>
            <h4>{user?.username}</h4>
          </a>
          <Divider style={{ margin: '3px 5px' }} />
          <Menu mode="inline" id="sidebar-menu" selectedKeys={[`/${currentActiveNavLink}/`]}>
            {/* <Menu.Item key="search" className="sidebar-item" icon={<IconSearch />}>
              <NavLink to='/#search'>{t('Qidiruv')}</NavLink>
            </Menu.Item> */}
            <Menu.Item key="/dashboard/" className="sidebar-item" icon={<IconHome />}>
              <NavLink to="/dashboard"> {t('Asosiy')}</NavLink>
            </Menu.Item>
            <Menu.Item key="/statistics/" className="sidebar-item" icon={<IconChart />}>
              <NavLink to="/statistics">{t('YTH statistikasi')}</NavLink>
            </Menu.Item>
            <Menu.Item key="/cards/" className="sidebar-item" icon={<IconExplore />}>
              <NavLink to="/cards">{t('YTH kartochkasi')}</NavLink>
            </Menu.Item>
            <Menu.Item key="/handbooks/" className="sidebar-item" icon={<BiBookAlt />}>
              <NavLink to="/handbooks">{t("Ma'lumotnomalar")}</NavLink>
            </Menu.Item>
            {
              user.is_superuser ?
                (
                  <Menu.SubMenu
                    key="users-menu"
                    title={t("Foydalanuvchilar")}
                    icon={<BiGroup style={{ minWidth: 24, width: 24 }} className="mr-2 sidebar-icon" />}
                  >
                    <Menu.Item key="/users/" className="submenu-item" icon={<BiGroup />}>
                      <NavLink to="/users">{t("Foydalanuvchilar")}</NavLink>
                    </Menu.Item>
                    <Menu.Item key="/external-users/" className="submenu-item" icon={<BiGroup />}>
                      <NavLink to="/external-users">{t("Tashqariga API")}</NavLink>
                    </Menu.Item>
                    <Menu.Item key="/groups/" className="submenu-item" icon={<BiGridAlt />}>
                      <NavLink to="/groups">{t("Guruhlar")}</NavLink>
                    </Menu.Item>
                  </Menu.SubMenu>
                )
                :
                !user.district && !isReadOnlyUser() ?
                  <Menu.Item key="/users/" className="sidebar-item" icon={<BiGroup />}>
                    <NavLink to="/users">{t("Foydalanuvchilar")}</NavLink>
                  </Menu.Item>
                  :
                  null
            }
            <Menu.Item key="logout" onClick={handleLogOut} className="sidebar-item" icon={<BiLogOut />}>
              <NavLink to="#">{t("Chiqish")}</NavLink>
            </Menu.Item>
          </Menu>
        </div>
        <Language />
      </Sider>

      <Layout id="main">
        <Content style={{ margin: '24px 16px 0' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 'calc(100vh - 48px)' }}>
            {props.children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default LayoutPage;