import React, { useState, lazy, Suspense, useMemo, useEffect } from "react";
import { Space, Tabs, Tag, Row, Col, Spin, Button, Badge } from "antd";

import { useHistory, useParams } from "react-router-dom";
import CardHeader from "./Cardheader";
import S from "../../../styles/cards";
import {
  getIdsFromArrayOfObjects,
  isReadOnlyUser,
  parseDataGettingObjectIds,
  t,
} from "../../../utils";
import StepOne from "./StepOne";
import NewCardContext from "../../../contexts/newCardContext";
import { BiHourglass } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../api/cards";
import actions from "../../../store/cardEdit/actions";
import persistCard from "../../../store/cardPersisted/actions";
import moment from "moment";
import HelpSidebarSection from "../../../components/HelpSidebarSection";
import { Spinner } from "../../../components";
import axios from "../../../utils/axios";

const { TabPane } = Tabs;
const StepTwo = lazy(() => import("./StepTwo"));
const StepThree = lazy(() => import("./StepThree"));
const StepFour = lazy(() => import("./StepFour"));
const HistoryOfChanges = lazy(() => import("./HistoryOfChanges"));
const Map = lazy(() => import("../../../components/Map/index"));

export default function AddCard(props) {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [mapVisible, setMapVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const fields = useSelector((state) => state.cardEdit || {});
  const { accident_day, accident_month, accident_year, date_accident } = fields;
  const stepOne = useSelector(
    (state) => state.cardEdit || { participants: [], vehicles: [] }
  );
  const activeTab =
    new URLSearchParams(props.location.search).get("active_tab") || "f5";
  const handleActiveTab = (value) => {
    history.push(`${props.location.pathname}?active_tab=` + value);
  };

  const handlers = {
    ACTIVE_TAB: (event) => handleActiveTab(event.key?.toLowerCase()),
  };

  const contextValue = {
    handleActiveTab,
    setMapVisible,
    activeTab,
  };

  const getHistoryCount = async () => {
    try {
      const { data } = await axios(
        `/cards/history/?card__id=${params.id}&page_size=1`
      );
      setCount(data.count);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await api.fetch(params.id);

        if (!data?.error) {
          const { vehicles, participants, ...rest } = data;
          let obj = {};
          for (let x in rest) {
            obj = { ...obj, [x]: getIdsFromArrayOfObjects(rest[x]) };
          }
          const parsedValue = {
            ...obj,
            isEdited: false,
            accident_day: moment(rest.date_accident).format("DD"),
            accident_month: moment(rest.date_accident).format("MM"),
            accident_year: moment(rest.date_accident).format("YYYY"),
            accident_time: moment(rest.date_accident).format("HH:mm"),
            initialDate: moment(rest.date_accident).utc(true).format(),
            fileList: rest.files.map((item) => ({
              uid: item.id.toString(),
              url: item.file,
              status: "done",
              name: item.name,
            })),
            attachmentsList: rest.attachments.map((item) => ({
              uid: item.id.toString(),
              url: item.file,
              status: "done",
              name: item.name,
            })),
            participants: participants?.map((item) => ({
              ...parseDataGettingObjectIds(item),
              key: item.id.toString(),
            })),
            vehicles: vehicles?.map((item) => ({
              ...parseDataGettingObjectIds(item),
              key: item.id.toString(),
              type: "driver",
              participants: item.participants?.map((item) => ({
                ...parseDataGettingObjectIds(item),
                key: item.id.toString(),
              })),
            })),
          };

          dispatch(actions.stepOne(parsedValue));
          dispatch(persistCard.setData(parsedValue));
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    })();
    getHistoryCount();
  }, []);

  const stats = useMemo(
    () =>
      [...stepOne.participants, ...stepOne.vehicles].reduce(
        (total, item) => {
          let injured = item.health_condition === "injured" ? 1 : 0;
          let dead = item.health_condition === "died" ? 1 : 0;

          item.participants?.forEach((i) => {
            if (i.health_condition === "injured") {
              injured += 1;
            } else if (i.health_condition === "died") {
              dead += 1;
            }
          });

          total.dead += dead;
          total.injured += injured;
          total.total += item?.participants ? item?.participants.length : 1;
          return total;
        },
        { injured: 0, dead: 0, total: 0 }
      ),
    [stepOne]
  );

  return (
    <NewCardContext.Provider value={contextValue}>
      <S.NewCard>
        <CardHeader />
        <Space
          style={{ marginBottom: "-2rem" }}
          className="statistics mb-4"
          size="large"
        >
          <Space>
            <h1>{stats.total === 0 ? "-" : stats.total}</h1>
            <p>{t("YTH ishtirokchilari")}</p>
          </Space>
          <Space>
            <h1>{stats.dead === 0 ? "-" : stats.dead}</h1>
            <p>{t("Xalok bo'lganlar")}</p>
          </Space>
          <Space>
            <h1>{stats.injured === 0 ? "-" : stats.injured}</h1>
            <p>{t("Jarohatlanganlar")}</p>
          </Space>
        </Space>
        {accident_day !== "sana" &&
          accident_day !== "00" &&
          accident_day !== "0" &&
          accident_month !== "oy" &&
          accident_month !== "00" &&
          accident_month !== "0" &&
          accident_year !== "Yil" &&
          accident_year !== "00" &&
          accident_year !== "0" && (
            <div
              style={{
                position: "relative",
                marginTop: "-2.2rem",
                marginBottom: "2rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                }}
              >
                <p>{accident_day === "" ? "--" : accident_day}-</p>
                <p>{accident_month === "" ? "--" : accident_month}-</p>
                <p>{accident_year === "" ? "--" : accident_year}</p>
              </div>
              <p style={{ marginLeft: "10px" }}>
                {t("YTH sodir bo'lgan vaqt")}{" "}
              </p>
            </div>
          )}
        {/* ------- Tabs ------ */}
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <>
            <section className="b-1 p-4 pl-0 b-radius-10">
              <div className="pl-4">
                <Tabs
                  activeKey={activeTab}
                  onChange={(key) => handleActiveTab(key)}
                  className="main-tab scrollable"
                  tabBarExtraContent={{
                    right: !isReadOnlyUser() ? (
                      <Badge count={count}>
                        <Button
                          className="grey mb-3"
                          onClick={() => handleActiveTab("history")}
                        >
                          {t("O'zgarishlar tarixi")}{" "}
                          <BiHourglass size="20px" className="ml-0" />
                        </Button>
                      </Badge>
                    ) : (
                      []
                    ),
                  }}
                >
                  <TabPane
                    className="pt-5"
                    tab={
                      <h3 className="mb-0 tab-title">
                        {t("1. Umumiy ma'lumotlar")}{" "}
                        <Tag className="f-code">F5</Tag>{" "}
                      </h3>
                    }
                    key="f5"
                  >
                    <Row>
                      <Col span={19}>
                        <StepOne />
                      </Col>
                      <HelpSidebarSection />
                    </Row>
                  </TabPane>
                  <TabPane
                    className="pt-5"
                    tab={
                      <h3 className="mb-0 tab-title">
                        {t("2. YTH ishtirokchilari")}{" "}
                        <Tag className="f-code">F6</Tag>{" "}
                      </h3>
                    }
                    key="f6"
                  >
                    <Row>
                      <Col span={19}>
                        <Suspense fallback={<Spin>loading</Spin>}>
                          <StepTwo />
                        </Suspense>
                      </Col>
                      <HelpSidebarSection />
                    </Row>
                  </TabPane>
                  <TabPane
                    className="pt-5"
                    tab={
                      <h3 className="mb-0 tab-title">
                        {t("3. Jarohatlanganlar")}{" "}
                        <Tag className="f-code">F7</Tag>{" "}
                      </h3>
                    }
                    key="f7"
                  >
                    <Row>
                      <Col span={19}>
                        <Suspense fallback={<Spin>loading</Spin>}>
                          <StepThree />
                        </Suspense>
                      </Col>
                      <HelpSidebarSection />
                    </Row>
                  </TabPane>
                  <TabPane
                    className="pt-5"
                    tab={
                      <h3 className="mb-0 tab-title">
                        {t("4. Ko'rilgan choralar")}{" "}
                        <Tag className="f-code">F8</Tag>{" "}
                      </h3>
                    }
                    key="f8"
                  >
                    <Row>
                      <Col span={19}>
                        <Suspense fallback={<Spin>loading</Spin>}>
                          <StepFour />
                        </Suspense>
                      </Col>
                      <HelpSidebarSection />
                    </Row>
                  </TabPane>
                  <TabPane className="pt-5" key="history">
                    <Row>
                      <Col span={19}>
                        <Suspense fallback={<Spin>loading</Spin>}>
                          <HistoryOfChanges />
                        </Suspense>
                      </Col>
                      <HelpSidebarSection />
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            </section>
            {mapVisible ? (
              <Suspense fallback={<div>Loading...</div>}>
                <Map
                  visible={mapVisible}
                  data={stepOne.location}
                  handleModal={() => setMapVisible(false)}
                />
              </Suspense>
            ) : null}
          </>
        )}
      </S.NewCard>
    </NewCardContext.Provider>
  );
}
