/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import axios from './axios';

function useDebounce(callback, delay = 1000) {
  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);
    return () => {
      return clearTimeout(handler);
    };
  }, [callback, delay]);
}

function useFetch({ uri = '' }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(uri);
      setData(data);
      setLoading(false);
    }
    catch (err) {
      setErrors(err);
      setLoading(false);
    }
  }
  useEffect(() => {
    if (!uri) return;
    fetchData();
  }, [uri]);

  return { data, errors, loading }
}

function useNetwork() {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  useEffect(() => {
    window.addEventListener("offline",
      () => setNetwork(window.navigator.onLine)
    );
    window.addEventListener("online",
      () => setNetwork(window.navigator.onLine)
    );
  });
  return isOnline;
};

export { useDebounce, useFetch, useNetwork };