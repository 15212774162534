import axios from '../utils/axios';

export const fetch = async () => {
  try {
    const { data } = await axios.get(`/users`);
    return data;
  }
  catch (err) {
    return { error: err, success: false }
  }
};

export const fetchById = async (id) => {
  try {
    const { data } = await axios.get(`/auth/users/${id}/`);
    return data;
  }
  catch (err) {
    return { error: err.response.data, success: false }
  }
};

export const fetchPermissions = async (id) => {
  try {
    const { data } = await axios.get(`/auth/permissions/`);
    return data;
  }
  catch (err) {
    return { error: err.response.data, success: false }
  }
};

