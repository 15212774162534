/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from 'react';
import { t, } from '../../utils';
import { Button, Table, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import axios, { cancelToken } from '../../utils/axios';
import Swal from 'sweetalert2';
import colors from '../../styles/colors';
import { AiOutlineDelete } from 'react-icons/ai';
import S from '../../styles/user';
import { BiPlusCircle } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { fetchPermissions } from '../../api/users';
import actions from '../../store/permissions/actions';

export default function PersonalInfo() {
  const source = cancelToken();
  const myAccount = useSelector(state => state.auth.user || {});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, total: 0, size: 10 });
  const defaultPage = new URLSearchParams(window.location.search).get('page') || 1;
  const defaultSize = new URLSearchParams(window.location.search).get('page_size') || 10;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    handlePage();
    (async () => {
      const data = await fetchPermissions();
      if (!data?.error) {
        dispatch(actions.setPermissions(data));
      }
    })();
    return () => source.cancel('Component unmounted')
  }, []);

  const handleDelete = async (id) => {
    Swal.fire({
      title: t("Ishonchingiz komilmi?"),
      text: t("Haqiqatdanham guruhni o'chirmoqchimisiz?"),
      icon: "warning",
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: '#E7E9EB',
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: 'swal-danger',
    }).then(async ({ value }) => {
      if (value) {
        try {
          setLoading(true);
          await axios.delete(`/auth/groups/${id}/`, { cancelToken: source.token });
          await handlePage();
          message.success(t("Ma'lumot muvaffaqiyatli yangilandi"));
        }
        catch (err) {
          console.error(err);
          setLoading(false);
        }
      }
    })
  }

  const handlePage = async (page = defaultPage, size = defaultSize) => {
    try {
      setLoading(true);
      const res = await axios.get(`/auth/groups/?page=${page}&page_size=${size}`, { cancelToken: source.token });
      if (res.data.results?.length) {
        setData(res.data.results);
        setPagination({ page: parseInt(page), total: res.data.count, size });
        history.push(`/groups/?page=${page}&page_size=${size}`);
      }
      setLoading(false);
    }
    catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  const columns = useMemo(() => [
    {
      title: t('#'),
      dataIndex: 'id',
      width: 30,
      render: (val, record, index) => {
        const { page, size } = pagination;
        const number = page > 1 ? (page - 1) * size + index + 1 : index + 1
        return number;
      }
    },
    {
      title: t('Guruh nomi'),
      dataIndex: 'name',
      render: (val, record) => val,
    },
    {
      title: t("O'chirish"),
      dataIndex: 'actions',
      width: 120,
      render: (val, record) => (
        <div onClick={e => e.stopPropagation()}>
          <Button type="default" className="delete-button" danger onClick={() => handleDelete(record.id)}>
            <AiOutlineDelete color={colors.danger} />
          </Button>
        </div>
      )
    }
  ], [data, myAccount.lang]);

  return (
    <S.AddUser className="p-3">
      <div className="text-right">
        <Button onClick={() => history.push('/groups/new')} id="add-card" type="primary" >
          {t("Guruh qo'shish")}
          {<BiPlusCircle className="has-icon" />}
        </Button>
      </div>
      <h2 style={{ color: colors.success }} className="mb-3">{t("Guruhlar")}</h2>
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        className="card-list"
        rowKey="id"
        scroll={{ x: true }}
        rowClassName="clickable"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => history.push(`/groups/${record.id}/`), // click row
          };
        }}
        pagination={{
          hideOnSinglePage: false,
          pageSize: pagination.size,
          current: pagination.page,
          total: pagination.total,
          showSizeChanger: true,
          showSizeChangerOptions: {},
          onChange: (page, size) => handlePage(page, size),
          pageSizeOptions: [20, 30, 50],
          showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,
          locale: { items_per_page: '/ ' + t('Sahifa') }
        }}
      />
    </S.AddUser>
  )
}
