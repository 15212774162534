import { createStore, combineReducers, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunkMiddleware from "redux-thunk";
import storage from "redux-persist/lib/storage";
// import { composeWithDevTools } from "redux-devtools-extension";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import logger from "redux-logger";

// ----------- Reducers ---------------
import authReducer from "./auth/reducers";
import { cardUpdatedFieldsReducer, cardReducer } from "./cards/reducers";
import cardEditReducer from "./cardEdit/reducers";
import persistedCardReducer from "./cardPersisted/reducers";
import handbookReducer from "./handbooks/reducers";
import permissionsReducer from "./permissions/reducers";
import preserveStateReducer from "./cardFilters/reducers";
import statisticsReducer from "./statisticsFilters/reducers";

const persistConfig = {
  key: "root",
  whitelist: [
    "auth",
    "card",
    "cardUpdatedFields",
    "handbooks",
    "permissions",
    "cardFilters",
    "statisticsFilter",
  ],
  storage,
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  auth: authReducer,
  card: cardReducer,
  cardEdit: cardEditReducer,
  persistedCard: persistedCardReducer,
  cardUpdatedFields: cardUpdatedFieldsReducer,
  handbooks: handbookReducer,
  permissions: permissionsReducer,
  cardFilters: preserveStateReducer,
  statisticsFilter: statisticsReducer,
});

const middlewares = [thunkMiddleware];
if (process.env.NODE_ENV === "development") {
  // middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(...middlewares));

const persistor = persistStore(store);

export { store as default, persistor };
