import styled from 'styled-components';
import colors from '../styles/colors';

const S = {};

S.Popup = styled.div`
  .search-area {
    padding-bottom: 12px;
    margin: 0 -24px 7px;
    padding: 0 24px 12px;
    border-bottom: 1px solid ${colors.border};
  }

  .title {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.5px;
  }
  .ant-list-items {
    position: sticky;
    top: 0;
    overflow: scroll;
    height: 100%;
    max-height: 330px;
    margin-bottom: -20px;

    &{
      scrollbar-width: thin;          /* "auto" or "thin" */
      scrollbar-color:#D0D5DB #fff;
    }

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #D0D5DB;
      border-radius: 20px;
      border: 3px solid #fff;
    }

    .custom-list-item {
      border: 1px solid #C3C5C9;
      border-bottom: 1px solid #C3C5C9;
      box-sizing: border-box;
      border-radius: 5px;
      margin: 6px 0;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      position: relative;
      cursor: pointer;

      &.active, &:hover {
        border: 1px solid ${colors.success};
        background-color: ${colors.successLight};
      }
      &.active .icon{
        display: inline-block;
      }
    }
  }
  
  .list-item-title {
    font-family: 'Roboto-medium';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;  
    color: #333333;
    margin-right: 12px;

    .icon {
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 28px;
      fill: ${colors.success};
      display: none;
    }
  }
  .list-item-info {
    font-size: 15px;
    line-height: 19px;
    color: #767677;
    margin: 0;
  }
`;

S.EmptyCard = styled.div`
  background: #FAFAFA;
  border-radius: 5px;
  max-width: 400px;
  padding: 30px 20px;
  text-align: center;
  margin: auto;

  h3 {
    margin-top: 10px;
  }
  button {
    margin-top: 10px;
  }
`

export default S;