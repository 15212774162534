import styled from 'styled-components';

const SingInPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px);
  flex-direction: column;
  padding: 40px;

  img {
    width: 100%;
    max-width: 500px;
  }
  h1 {
    margin-top: 20px;
    font-weight: 500;
  }
`;

export default SingInPage;