import * as types from "../actionTypes";
import moment from 'moment';

const initialState = {
  accident_types: [],
  health_condition: [],
  region_id__in: null,
  district_id__in: null,
  accident_hour: null,
  date_accident: [moment(moment().startOf('year')), moment(moment().endOf('date'))],
  date_accident__gte: moment(moment().startOf('year')).utc(true).format(),
  date_accident__lte: moment(moment().endOf('date')).utc(true).format(),
  date_accident__time__lte: null,
  date_accident__time__gte: null,
  status__in: [],
  location_isempty: false,
};

const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_STATISTICS_FILTER: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.RESET_STATISTICS_FILTER:
    case types.AUTH_SIGN_OUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default statisticsReducer;
