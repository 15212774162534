import React, { useCallback } from 'react';
import Hotkeys from 'react-hot-keys';

export default function HotkeyBinding({ shortKey = 'alt+2', children }) {
  const onKeyDown = useCallback((keyName, e, handle) => {
    console.log(keyName, e, handle);
  }, [shortKey]);

  const onKeyUp = useCallback((keyName, e, handle) => {
    console.log(keyName, e, handle);
  }, [shortKey]);

  return (
    <Hotkeys
      keyName={shortKey}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
    >
      {children}
    </Hotkeys>
  )
}
