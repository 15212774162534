/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useState } from "react";
import { Button, Tag, Space, message } from "antd";
import { t, getHandbookName, getNotNullValues } from "../../../utils";
import { BiCheckCircle, BiBlock, BiLockOpenAlt } from "react-icons/bi";
import S from "../../../styles/cards";
import colors from "../../../styles/colors";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../api/cards";
import { useHistory, Prompt } from "react-router-dom";
import Swal from "sweetalert2";
import * as types from "../../../store/actionTypes";
import { statusColors } from "../../../constants";
import { fieldscheckOnStepsChange } from "../../../utils/card";
import newCardContext from "../../../contexts/newCardContext";

export default function Cardheader(props) {
  const fields = useSelector((state) => state.card || {});
  const updatedFields = useSelector((state) => state.cardUpdatedFields);
  const [loading, setLoading] = useState(false);
  const [canLeaveThePage, setCanLeaveThePage] = useState(true);
  const myAccount = useSelector((state) => state.auth.user);
  const { handleActiveTab } = useContext(newCardContext);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleCardDeletion = async () => {
    const { id } = fields;
    if (!id) return;

    Swal.fire({
      title: t("Ushbu YTH kartochkasini hisobdan chiqarmoqchimisiz?"),
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: "#E7E9EB",
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: "swal-danger",
    }).then(async ({ value }) => {
      if (value) {
        Swal.fire({
          title: t(
            "Hisobdan chiqarilgan kartochkalarni qayta tiklash mumkin bo'lmaydi. Davom ettirishni xoxlaysizmi?"
          ),
          confirmButtonText: t("Tasdiqlash"),
          cancelButtonText: t("Bekor qilish"),
          cancelButtonColor: "#E7E9EB",
          confirmButtonColor: colors.danger,
          showCancelButton: true,
          customClass: "swal-danger",
        }).then(async ({ value: isTrue }) => {
          if (isTrue) {
            try {
              const res = await api.deleteCard(id);
              if (!res.error) {
                dispatch({ type: types.CARD_DELETE });
                history.replace("/cards");
              }
            } catch (err) {
              console.error(err);
              message.error(t("Xatolik yuz berdi"));
            }
          }
        });
      }
    });
  };

  const handleSave = useCallback(
    async (url) => {
      const res = fieldscheckOnStepsChange({
        fields,
        redirecHandler: handleActiveTab,
      });
      if (res) {
        const { id, status } = fields;
        setCanLeaveThePage(false);
        if (id && status) {
          setLoading(true);
          try {
            const res = await api.update({
              data: getNotNullValues(fields),
              id,
            });
            if (res.error) {
              let messages = [];
              for (let x in res.error) {
                messages.push(
                  <li key={x}>
                    {getHandbookName(x)}: {res.error[x].join(", ")}
                  </li>
                );
              }

              return message.error(messages, 10);
            }
            dispatch({ type: types.CARD_COMPLETED });
            setLoading(false);
            history.replace(url);
          } catch (err) {
            console.error(err);
            // message.error(err.message, 3);
            setLoading(false);
          }
        } else {
          dispatch({ type: types.CARD_COMPLETED });
          history.replace(url);
        }
      }
    },
    [fields, updatedFields]
  );

  const handleSaveConfirmation = (url) => {
    Swal.fire({
      title: t("O'zgarishlarni saqlash"),
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      text: t("Kartochkadagi o'zgarishlarni saqlab qo'yish."),
      cancelButtonColor: "#E7E9EB",
      confirmButtonColor: colors.success,
      showCancelButton: true,
      customClass: "swal-danger",
    }).then(async ({ value }) => {
      if (value) {
        setCanLeaveThePage(false);
        handleSave(url, true);
      } else {
        setCanLeaveThePage(false);
        dispatch({ type: types.CARD_DELETE });
        history.push(url);
      }
    });
  };

  const statusType = fields.is_deleted
    ? "excluded"
    : fields.status === "filling" && fields.is_late
    ? "filling_late"
    : fields.status;
  const status = statusType
    ? statusColors[statusType][myAccount.lang || "uz"]
    : statusColors["filling"][myAccount.lang || "uz"];

  return (
    <S.CardHeader className="vertical-alignment">
      <Prompt
        message={(location, action) => {
          const isLocationChanged = !location.pathname.includes(`/cards/new`);
          if (isLocationChanged && fields.id) {
            handleSaveConfirmation(location.pathname);
            return false;
          }
          return true;
        }}
        when={canLeaveThePage}
      />
      <Tag
        shape="round"
        style={{
          padding: "4px 9px",
          color: statusColors[statusType]?.color,
          fontSize: 14,
          display: fields.status ? "inline-block" : "none",
        }}
        color={statusColors[statusType]?.bg}
      >
        {status}
      </Tag>
      <h1>
        {t("YTH")} {fields.id ? `#${fields.id}` : null}
      </h1>
      <Space>
        <Button
          type="primary"
          onClick={() => handleSaveConfirmation("/cards")}
          loading={loading}
        >
          <BiCheckCircle size="20px" className="icon" />
          {t("Saqlash")}
          <Tag
            className="f-code"
            style={{
              border: "1px solid rgba(255, 255, 255, 0.31)",
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              color: "#fff",
            }}
          >
            F9
          </Tag>
        </Button>
        <Button className="grey">
          <BiLockOpenAlt size="20px" className="icon" />
          {t("Qayta ochish")}
          <Tag className="f-code">F10</Tag>
        </Button>
        <Button danger onClick={handleCardDeletion}>
          <BiBlock size="20px" className="icon" />
          {t("Hisobdan chiqarish")}
          <Tag className="f-code" color="magenta">
            F11
          </Tag>
        </Button>
      </Space>
    </S.CardHeader>
  );
}
