/* eslint-disable import/no-anonymous-default-export */
import regular from '../assets/fonts/roboto/Roboto-Regular.ttf';
import bold from '../assets/fonts/roboto/Roboto-Bold.ttf';
import medium from '../assets/fonts/roboto/Roboto-Medium.ttf';
import colors from './colors';

export default `
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto'), url('${regular}') format('woff');
  }

  @font-face {
    font-family: 'Roboto-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto-Medium'), url('${medium}') format('woff');
  }
  

  @font-face {
    font-family: 'Roboto-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Roboto-Bold'), url('${bold}') format('woff');
  }
  

  h1  {
    font-family: "Roboto-Bold";
    font-size: 34px;
    line-height: 40px;
    color: ${colors.text};
  }

  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: ${colors.text};
  }

  label {
    font-family: "Roboto-Medium";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    color: ${colors.text};
  }
  .tab {
    font-family: "Roboto-Medium";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.text};
  }

  label + span {
    color: ${colors.secondary};
  }
  .text-right {
    text-align: right;
  }
  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-justify {
    text-align: justify;
  }
  .text-capitalize {
    text-transform: capitalize;
  }
  .text-uppercase {
    text-transform: uppercase;
  }
  .text-lowercase {
    text-transform: lowercase;
  }
  .text-grey {
    color: #8D8E8F;
  }
  .text-danger {
    color:${colors.danger};
  }
  .text-secondary {
    color: ${colors.secondary};
  }
`;
