/* eslint-disable import/no-anonymous-default-export */
import AddCard from './containers/Cards/AddCard/index';
import Login from './containers/Auth/Login';
import Cards from './containers/Cards';
import EditCard from './containers/Cards/EditCard';
import Handbooks from './containers/Handbooks';
import Profile from './containers/Profile';
import Users from './containers/Users';
import ExternalUsers from './containers/ExternalUsers';
import EditGroup from './containers/Groups/EditGroup';
import Groups from './containers/Groups';
import NewGroup from './containers/Groups/NewGroup';
import AddUser from './containers/Users/AddUser';
import AddExternalUsers from './containers/ExternalUsers/AddUser';
import UserEdit from './containers/Users/Edit';
import UserExternalEdit from './containers/ExternalUsers/Edit';
import HandbooksList from './containers/Handbooks/List';
import HandbooksNested from './containers/Handbooks/Nested';
import HandbooksNestedDetails from './containers/Handbooks/NestedDetails';
import PageNotFound from './components/NotFound';
import Statistics from './containers/Statistics';
import Dashboard from './containers/Dashboard';

export default [
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
    readOnly: false,
  },
  {
    path: '/cards/new/',
    component: AddCard,
    exact: true,
    readOnly: false,
  },
  {
    path: ['/cards', '/'],
    component: Cards,
    exact: true,
    readOnly: true,
  },
  {
    path: '/cards/:id/',
    component: EditCard,
    exact: true,
    readOnly: true,
  },
  {
    path: '/statistics',
    component: Statistics,
    exact: true,
    readOnly: true,
  },
  {
    path: '/handbooks',
    component: Handbooks,
    exact: true,
    readOnly: true,
  },
  {
    path: '/handbooks/:name/:id?',
    component: HandbooksList,
    exact: true,
    readOnly: true,
  },
  {
    path: '/nested-handbooks/:name/',
    component: HandbooksNested,
    exact: true,
    readOnly: true,
  },
  {
    path: '/nested-handbooks/:name/:nestedField/:id',
    component: HandbooksNestedDetails,
    exact: true,
    readOnly: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/users',
    component: Users,
    exact: true,
    protected: true,
    allowed: ['region']
  },
  {
    path: '/groups',
    component: Groups,
    exact: true,
    protected: true,
  },
  {
    path: '/groups/new',
    component: NewGroup,
    exact: true,
    protected: true,
  },
  {
    path: '/groups/:id',
    component: EditGroup,
    exact: true,
    protected: true,
  },
  {
    path: '/users/new',
    component: AddUser,
    exact: true,
    protected: true,
    allowed: ['region'],
  },
  {
    path: '/external-users',
    component: ExternalUsers,
    exact: true,
    protected: true,
  },
  {
    path: '/users-external/new',
    component: AddExternalUsers,
    exact: true,
    protected: true,
    allowed: ['region']
  },
  {
    path: '/users/:id/',
    component: UserEdit,
    exact: true,
    protected: true,
    allowed: ['region']
  },
  {
    path: '/external-users/:id/',
    component: UserExternalEdit,
    exact: true,
    protected: true,
  },
  {
    path: '/profile',
    component: Profile,
    exact: true,
    readOnly: true,
  },
  {
    path: '*',
    component: PageNotFound,
  },
];

