import React, { useMemo, useState } from 'react';
import S from '../../styles/profile';
import imageBadge from '../../assets/images/profile/badge.png';
import { t } from '../../utils';
import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import PersonalInfo from './PersonalInfo';
import SecurityInfo from './SecurityInfo';

const { TabPane } = Tabs;

export default function Profile(props) {
  const myAccount = useSelector(state => state.auth.user || {});
  const { districts, regions } = useSelector(state => state.handbooks || {});
  const [activeKey, setActiveKey] = useState('personal');
  const { region, district } = useMemo(() => {
    return {
      region: regions?.find(item => item.id === myAccount.region),
      district: districts?.find(item => item.id === myAccount.district),
    }
  }, [myAccount]);


  return (
    <>
      <h2>{t("Profil")}</h2>
      <S.Container className="b-1 b-radius">
        <S.Header className="profile p-4 bb-1">
          <img src={imageBadge} alt="DTP" className="profile__img" />
          <div className="profile__info">
            <h3 className="profile__name">{myAccount.username}</h3>
            <h4 className="profile__location">{`${region ? region[`name_${myAccount.lang}`] : ''} ${region ? ',' : ''} ${district ? district[`name_${myAccount.lang}`] : ''}`}</h4>
          </div>
        </S.Header>
        <S.Form className="p-4">
          <Tabs
            type="card"
            className="mb-0"
            onChange={(key) => setActiveKey(key)}
            activeKey={activeKey}
          >
            <TabPane tab={t("Shaxsiy")} key="personal" className="p-3 bx-1 bb-1 b-radius-right-bottom b-radius-left-bottom">
              <PersonalInfo />
            </TabPane>
            <TabPane tab={t("Avtorizatsiya")} key="security" className="p-3 bx-1 bb-1 b-radius-right-bottom b-radius-left-bottom">
              <SecurityInfo />
            </TabPane>
          </Tabs>

        </S.Form>
      </S.Container>

    </>
  )
}
