import React, { useEffect, useState } from 'react';

import { Upload, Modal, Button } from 'antd';
import { PlusOutlined, PaperClipOutlined } from '@ant-design/icons';
import configs from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../store/actionTypes';
import { t } from '../utils';
import Swal from 'sweetalert2';
import colors from '../styles/colors';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function PicturesWall({ isUploadTypeCard = false, files = [], accept = 'image/png, image/jpeg, .doc, .docx, .pdf', isDisabled = false }) {
  const { token } = useSelector(state => state.auth);
  const actionType = types.CARD_EDIT_STEP_ONE;
  const dispatch = useDispatch();
  const [data, setData] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: files,
  });

  useEffect(() => {
    setData(state => ({ ...state, fileList: files }))
  }, [files]);

  const handleCancel = () => setData(state => ({ ...state, previewVisible: false }));

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setData(state => ({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    }));
  };

  const handleChange = (req) => {
    setData(state => ({ ...state, fileList: req.fileList }));
    setDataToRedux(req.fileList);
  };

  const handleRemove = file => {
    return Swal.fire({
      title: t("Ushbu faylni o'chirmoqchimisiz?"),
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      // text: t("YTH ishtirokchisini o'chirmoqchimisiz, ushbu ishtirokchi uchun kiritilgan barcha ma'lumotlar o'chib ketadi"),
      cancelButtonColor: '#E7E9EB',
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: 'swal-danger',
    }).then(async ({ value }) => {
      if (value) {
        const fileList = data.fileList.filter(item => {
          if (file.response) {
            return item.response ? item.response.id !== file.response.id : item.id !== file.response.id;
          } else {
            return item.response ? item.response.id !== file.uid : item.uid !== file.uid;
          }
        });
        setData(state => ({ ...state, fileList }));
        setDataToRedux(fileList);
      }
      return false;
    });
  }

  const setDataToRedux = (data) => {
    const files = data.map(item => {
      if (item.response) {
        return item.response.id
      }
      return item.uid
    });

    const result = isUploadTypeCard ? { files } : { attachments: files };
    dispatch({ type: actionType, data: result });
  };


  const uploadButtonCard = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{t("Fayl yuklash")}</div>
    </div>
  );

  const uploadButton = (
    <Button className="mt-4 default focusable" tabIndex="0" disabled={isDisabled}>
      {t("Asosni kiriting")}
      <PaperClipOutlined style={{ marginLeft: 5, fontSize: 20, verticalAlign: 'middle' }} />
    </Button>
  );

  const showUploadButton = isUploadTypeCard ? uploadButtonCard : uploadButton;
  const { previewVisible, previewImage, fileList, previewTitle } = data;

  return (
    <>
      <Upload
        action={configs.URL.REACT_APP_BACKEND_API_URL + '/files/'}
        listType={isUploadTypeCard ? "picture-card" : ""}
        headers={{ Authorization: `Token ${token}` }}
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        accept={accept}
        onRemove={handleRemove}
        data={(file) => ({ name: file.name, content_type: file.type })}
        showUploadList={{ showDownloadIcon: true }}
        disabled={isDisabled}
      >
        {showUploadButton}
      </Upload>
      {
        isUploadTypeCard ?
          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
          :
          null
      }
    </>
  );
}

export default PicturesWall;