import React, { useState, useMemo, useCallback, useEffect, useRef, } from 'react';
import { Table, Input, Button } from 'antd';
import { Link, useParams, } from 'react-router-dom';
import { useSelector } from 'react-redux';
import S from '../../styles/handbooks';
import { getHandbookName, t } from '../../utils';
import { BreadCrumbs } from '../../components';
import handbookNames from '../../constants/handbookNames';
import { handbookAxios } from '../../utils/axios';
import Search from 'antd/lib/transfer/search';
import HandbookSearch from '../../components/HandbookSearch';

export default function Handbooks() {
  const myAccount = useSelector(state => state.auth.user || { lang: 'uz' });
  const params = useParams();
  const [data, setData] = useState([]);
  const [query, setQuery] = useState('');
  const currentHandbook = handbookNames[params.name];
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 25, count: 0 });
  const searchRef = useRef('');
  useEffect(() => {
    handlePage();
  }, []);

  const handlePage = async (page = 1, size = 25) => {
    if (currentHandbook.isNestedStatic) {
      setLoading(true);
      setData(currentHandbook.extraFields.find(item => item.name === currentHandbook.nestedParentName).values)
      setLoading(false);
      setPagination({ page: parseInt(1), total: data.count });
    } else {
      try {
        setLoading(true);
        const { data } = await handbookAxios.get(`/${currentHandbook.nestedParentUrl}/?page_size=10000`);
        setData(data.results);
        setPagination({ page, size, count: data.count });
        setLoading(false);
      }
      catch (err) {
        console.erro(err);
        setLoading(false);
      }
    }
  }

  const columns = useMemo(() => [
    {
      key: 'url',
      title: '#',
      dataIndex: 'url',
      width: 30,
      render: (val, record, index) => {
        return index + 1;
      }
    },
    {
      key: 'name',
      title: t("Ma'lumotnoma nomi"),
      dataIndex: 'name',
      width: '100%',
      render: (val, record, index) => {
        return (
          <Link
            to={`/nested-handbooks/${params.name}/${currentHandbook.nestedParentName}/${record.id}`}
            className="d-flex w-100 clickable"
          >
            {record[`name_${myAccount.lang}`]}
          </Link>
        )
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      // sorter: (a, b) => {
      //   if (a[myAccount.lang]?.toLowerCase() > b[myAccount.lang]?.toLowerCase()) {
      //     return 1
      //   } else if (a[myAccount.lang]?.toLowerCase() < b[myAccount.lang]?.toLowerCase()) {
      //     return -1
      //   } else {
      //     return 0
      //   }
      // },
      defaultSortOrder: 'ascend',
    },
  ], [myAccount.lang]);

  const handleSearch = async () => {
    try {
      const { data } = await handbookAxios.get(`/${currentHandbook.nestedParentUrl}/?page_size=10000&search=${query}`);
      setData(data.results);
      setPagination({ page: 1, size: data.count, count: data.count })
    }
    catch (err) {
      console.error(err);
    }
  };

  const handleClear = () => {
    setQuery('');
    handlePage();
  }

  return (
    <>
      <BreadCrumbs
        extra={[
          {
            url: '/handbooks',
            title: t("Ma'lumotnomalar")
          },
          {
            url: '#',
            title: getHandbookName(currentHandbook.nestedParentKey)
          },
        ]}
      />
      <S.List className="b-1 b-radius">
        <div className="d-flex mb-4 p-3 bb-1">
          <h2 className="mb-0">{getHandbookName(params.name)}</h2>
        </div>

        <div className="mb-4 mx-4">
          <HandbookSearch
            query={query}
            queryHandler={setQuery}
            searchHandler={handleSearch}
            clearHandler={handleClear}
          />
        </div>
        <Table
          dataSource={data}
          columns={columns}
          className="card-list px-4"
          rowKey="name"
          scroll={{ x: true }}
          loading={loading}
          pagination={{
            hideOnSinglePage: true,
            pageSizeOptions: [10, 20, 30],
            showSizeChanger: true,
            current: pagination.page,
            pageSize: pagination.size || 25,
            total: pagination.total,
            onChange: (page, size) => handlePage(page, size),
            showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`
          }}
        />
      </S.List>
    </>
  )
}
