/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Button, Tag, Space, message } from "antd";
import {
  t,
  allowNullValues,
  getHandbookName,
  isReadOnlyUser,
} from "../../../utils";
import { BiCheckCircle, BiBlock, BiLockOpenAlt } from "react-icons/bi";
import S from "../../../styles/cards";
import colors from "../../../styles/colors";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../api/cards";
import { useHistory, Prompt, useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as types from "../../../store/actionTypes";
import configs, { statusColors } from "../../../constants";
import { debounce } from "lodash";
import actions from "../../../store/cardEdit/actions";
import Hotkey from "../../../components/HotkeyBinding";

export default function Cardheader(props) {
  const fields = useSelector((state) => state.cardEdit || {});
  const [isEdited, setIsEdited] = useState(fields.isEdited);
  const [loading, setLoading] = useState(false);
  const [canLeaveThePage, setCanLeaveThePage] = useState(true);
  const myAccount = useSelector((state) => state.auth.user || {});
  const isDisabled = fields.is_deleted || isReadOnlyUser();

  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const handleCardDeletion = async () => {
    const { id } = fields;
    if (!id) return;

    Swal.fire({
      title: t("Ushbu YTH kartochkasini hisobdan chiqarmoqchimisiz?"),
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: "#E7E9EB",
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: "swal-danger",
    }).then(async ({ value }) => {
      if (value) {
        Swal.fire({
          title: t(
            "Hisobdan chiqarilgan kartochkalarni qayta tiklash mumkin bo'lmaydi. Davom ettirishni xoxlaysizmi?"
          ),
          confirmButtonText: t("Tasdiqlash"),
          cancelButtonText: t("Bekor qilish"),
          cancelButtonColor: "#E7E9EB",
          confirmButtonColor: colors.danger,
          showCancelButton: true,
          customClass: "swal-danger",
        }).then(async ({ value: isTrue }) => {
          if (isTrue) {
            try {
              const res = await api.deleteCard(id);
              if (res === true) {
                dispatch({ type: types.CARD_DELETE });
                history.goBack();
              } else {
                console.error(res.error)
                message.error(t("Xatolik yuz berdi"));
              }
            } catch (err) {
              console.error(err);
              message.error(t("Xatolik yuz berdi"));
            }
          }
        });
      }
    });
  };

  const autoUpdate = debounce(
    (event) => handleSave(event, true),
    configs.UPDATE_DURATION
  );
  useEffect(() => setIsEdited(fields.isEdited), [fields]);
  useEffect(() => {
    if (fields.isEdited) {
      autoUpdate();
      return autoUpdate.cancel;
    }
  }, [fields.isEdited]);

  const handleSaveConfirmation = (url, forceToLeave) => {
    Swal.fire({
      title: t("O'zgarishlarni saqlash"),
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      text: t("Kartochkadagi o'zgarishlarni saqlab qo'yish."),
      cancelButtonColor: "#E7E9EB",
      confirmButtonColor: colors.success,
      showCancelButton: true,
      customClass: "swal-danger",
    }).then(async ({ value }) => {
      if (value) {
        setCanLeaveThePage(false);
        handleSave(url, true, true);
      } else {
        setCanLeaveThePage(false);
        if (url.includes("/cards")) {
          history.push('/cards');
        } else {
          history.push(url);
        }
      }
    });
  };

  const handleSave = async (url, isAutoUpdate = false, forceToLeave) => {
    const { id, status } = fields;
    setCanLeaveThePage(false);

    if (id && status) {
      try {
        setLoading(true);
        const res = await api.updateForLog({
          data: allowNullValues(fields),
          id,
        });
        if (res.error) {
          let messages = [];
          for (let x in res.error) {
            messages.push(
              <li key={x}>
                {getHandbookName(x)}: {res.error[x].join(", ")}
              </li>
            );
          }
          setLoading(false);
          return message.error(messages, 10);
        }

        if (!res.error) {
          if (isAutoUpdate && res?.isUpdated !== false && !forceToLeave) {
            message.success(t("Ma'lumot avtomatik ravishda saqlanmoqda."), 10);
            setIsEdited(false);
            dispatch(actions.stepOne({ isEdited: false }));
            setLoading(false);
          } else {
            if (res?.isUpdated !== false) {
              message.success(t("Ma'lumot muvaffaqiyatli yangilandi"));
            }
            dispatch({ type: types.CARD_EDIT_COMPLETED });
            history.push('/cards');
          }
        } else {
          message.error(t("Hatolik yuz berdi"));
          setLoading(false);
        }
        setLoading(false);
      } catch (err) {
        const errors = err.response?.data;
        let messages = [];
        console.error(err);
        for (let x in errors) {
          messages.push(`${x}: ${errors[x].join(",")}`);
        }
        if (messages.length) {
          message.error(messages);
        }

        setLoading(false);
      }
    }
  };

  const statusType = fields.is_deleted
    ? "excluded"
    : fields.status === "filling" && fields.is_late
      ? "filling_late"
      : fields.status;
  const status = statusType
    ? statusColors[statusType][myAccount.lang || "uz"]
    : statusColors["filling"][myAccount.lang || "uz"];

  return (
    <S.CardHeader className="vertical-alignment">
      <Prompt
        message={(location, action) => {
          const isLocationChanged = !location.pathname.includes(
            `/cards/${params.id}`
          );
          if (isLocationChanged && isEdited) {
            handleSaveConfirmation(location.pathname);
            return false;
          }
          return true;
        }}
        when={canLeaveThePage}
      />

      <Tag
        shape="round"
        style={{
          padding: "4px 9px",
          color: statusColors[statusType]?.color,
          fontSize: 14,
          display: fields.status ? "inline-block" : "none",
        }}
        color={statusColors[statusType]?.bg}
      >
        {status}
      </Tag>

      <h1>
        {t("YTH")} {fields.id ? `#${fields.id}` : null}
      </h1>
      <Space>
        <Hotkey shortKey={"alt+f7"}>
          <Button
            type="primary"
            onClick={() => handleSaveConfirmation("/cards")}
            disabled={isDisabled}
            loading={loading}
          >
            <BiCheckCircle size="20px" className="icon" />
            {t("Saqlash")}
            <Tag
              className="f-code"
              style={{
                border: "1px solid rgba(255, 255, 255, 0.31)",
                backgroundColor: "rgba(255, 255, 255, 0.25)",
                color: "#fff",
              }}
            >
              F9
            </Tag>
          </Button>
        </Hotkey>
        {/* <Hotkey shortKey={"alt+f6"}>
          <Button className="grey" disabled={isDisabled}>
            <BiLockOpenAlt size="20px" className="icon" />
            {t("Qayta ochish")}
            <Tag className="f-code">F10</Tag>
          </Button>
        </Hotkey> */}
        <Hotkey shortKey={"alt+f6"}>
          <Button danger onClick={handleCardDeletion} disabled={isDisabled}>
            <BiBlock size="20px" className="icon" />
            {t("Hisobdan chiqarish")}
            <Tag className="f-code" color="magenta">
              F11
            </Tag>
          </Button>
        </Hotkey>
      </Space>
    </S.CardHeader>
  );
}
