import React, { useRef } from "react";
import S from "../../styles/reports";
import { filterParams, t } from "../../utils";
import AccidentTypes from "./AccidentTypes";
import AccidentCasual from "./AccidentCasual";
import ParticipantTypes from "./ParticipantTypes";
import StreetSignificance from "./StreetSignificance";
import PropTypes from "prop-types";
import { exportTableToExel } from "../../utils/printer";
import { useState } from 'react';
import Filters from './Filters';
import momentLocale from '../../utils/moment';
import { useSelector } from 'react-redux';
export default function Reports() {
  const parentRef = useRef();
  const myAccount = useSelector(state => state.auth.user || {});
  const [filters, setFilters] = useState({ region_id: myAccount.region });
  const moment = momentLocale();
  const [params, setParams] = useState(`&date_accident__gte=${moment().startOf('year').utc(true).toISOString()}&date_accident__lte=${moment().endOf('year').utc(true).toISOString()}${filters.region_id ? `&region_id=${filters.region_id}` : ''}`)

  const handleParams = () => {
    const { month, year, date_accident__gte, date_accident__lte, region_id } = filters;
    const formatedDate = {
      date_accident__gte: null,
      date_accident__lte: null,
    };
    if (month) {
      const date = moment().set({
        year: moment(year).format('YYYY'),
        month: moment(month).format('MMMM')
      });
      formatedDate.date_accident__gte = date?.startOf('month');
      formatedDate.date_accident__lte = date?.endOf('month');
    } else if (year) {
      const date = moment().set({
        year: moment(year).format('YYYY'),
      });
      formatedDate.date_accident__gte = date?.startOf('year');
      formatedDate.date_accident__lte = date?.endOf('year');
    } else {
      formatedDate.date_accident__gte = date_accident__gte?.startOf('date');
      formatedDate.date_accident__lte = date_accident__lte?.endOf('date');
    }

    const params = filterParams({
      region_id,
      date_accident__gte: month ?
        formatedDate.date_accident__gte?.startOf('month').utc(true).toISOString()
        :
        formatedDate.date_accident__gte?.startOf('year').utc(true).toISOString(),
      date_accident__lte: month ?
        formatedDate.date_accident__lte?.endOf('month').utc(true).toISOString()
        :
        formatedDate.date_accident__lte?.endOf('year').utc(true).toISOString(),
    });

    const defaultParams = `&date_accident__gte=${moment().startOf('year').utc(true).toISOString()}&date_accident__lte=${moment().endOf('year').utc(true).toISOString()}`
    setParams(params ? params : defaultParams);
  };

  const data = [
    {
      type: "F-002",
      title: t("YTH turlari bo'yicha"),
      key: "accident_types",
      ref: React.createRef(""),
      ReportComponent: AccidentTypes,
    },
    {
      type: "F-008",
      title: t("YTH keltirib chiqaruvchi holatlar"),
      key: "accident_causal",
      ref: React.createRef(""),
      ReportComponent: AccidentCasual,
    },
    {
      type: "F-022",
      title: t("YTH ishtirokchi turlari bo'yicha"),
      key: "participant_types",
      ref: React.createRef(""),
      ReportComponent: ParticipantTypes,
    },
    {
      type: "F-007",
      title: t("Yo'l ahamiyati bo'yicha"),
      key: "street_significance",
      ref: React.createRef(""),
      ReportComponent: StreetSignificance,
    },
  ];

  return (
    <S.Main ref={parentRef} className="mt-5">
      <Filters
        filters={filters}
        handleParams={handleParams}
        setFilters={setFilters}
      />
      {data.map((item) => {
        const { type, title, key, ref, ReportComponent } = item;
        return (
          <ReportComponent
            key={key}
            tableRef={ref}
            type={type}
            title={title}
            params={params}
            exportToExcel={() =>
              exportTableToExel({ table: ref.current, title })
            }
          />
        );
      })}
    </S.Main>
  );
}

Reports.propTypes = {
  params: PropTypes.string,
};

Reports.defaultProps = {
  params: "",
};
