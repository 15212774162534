export const AUTH_SIGN_IN = "AUTH_SIGN_IN";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_CHANGE_LANG = "AUTH_CHANGE_LANG";
export const UPDATE_USER = "UPDATE_USER";

export const HANDBOOKS_SET = "HANDBOOKS_SET";
export const HANDBOOKS_DELETE = "HANDBOOKS_DELETE";
export const HANDBOOKS_UPDATE = "HANDBOOKS_UPDATE";

export const PERMISSIONS_SET = "PERMISSIONS_SET";
export const PERMISSIONS_DELETE = "PERMISSIONS_DELETE";
export const PERMISSIONS_UPDATE = "PERMISSIONS_UPDATE";

export const CARD_PERSIST = "CARD_PERSIST";
export const CARD_PERSIST_DELETE = "CARD_PERSIST_DELETE";

export const CLEAR_CARD_UPDATED_FIELDS = "CLEAR_CARD_UPDATED_FIELDS";
export const CARD_UPDATED_FIELDS = "CARD_UPDATED_FIELDS";
export const CARD_DELETE = "CARD_DELETE";
export const CARD_STEP_ONE = "CARD_STEP_ONE";
export const CARD_COMPLETED = "CARD_COMPLETED";
export const CARD_STEP_TWO = "CARD_STEP_TWO";
export const CARD_STEP_TWO_COMPLETED = "CARD_STEP_TWO_COMPLETED";
export const CARD_STEP_FOUR = "CARD_STEP_FOUR";
export const CARD_STEP_TWO_ADD_PARTICIPANT = "CARD_STEP_TWO_ADD_PARTICIPANT";
export const CARD_STEP_TWO_EDIT_PARTICIPANT = "CARD_STEP_TWO_EDIT_PARTICIPANT";
export const CARD_STEP_TWO_DELETE_PARTICIPANT =
  "CARD_STEP_TWO_DELETE_PARTICIPANT";
export const CARD_STEP_TWO_ADD_PASSENGER = "CARD_STEP_TWO_ADD_PASSENGER";
export const CARD_STEP_TWO_EDIT_PASSENGER = "CARD_STEP_TWO_EDIT_PASSENGER";
export const CARD_STEP_TWO_DELETE_PASSENGER = "CARD_STEP_TWO_DELETE_PASSENGER";

export const CLEAR__EDIT_UPDATED_FIELDS = "CLEAR__EDIT_UPDATED_FIELDS";
export const CARD_EDIT_UPDATED_FIELDS = "CARD_EDIT_UPDATED_FIELDS";
export const CARD_EDIT_DELETE = "CARD_EDIT_DELETE";
export const CARD_EDIT_STEP_ONE = "CARD_EDIT_STEP_ONE";
export const CARD_EDIT_COMPLETED = "CARD_EDIT_COMPLETED";
export const CARD_EDIT_STEP_TWO = "CARD_EDIT_STEP_TWO";
export const CARD_EDIT_STEP_TWO_COMPLETED = "CARD_EDIT_STEP_TWO_COMPLETED";
export const CARD_EDIT_STEP_FOUR = "CARD_EDIT_STEP_FOUR";
export const CARD_EDIT_STEP_TWO_ADD_PARTICIPANT =
  "CARD_EDIT_STEP_TWO_ADD_PARTICIPANT";
export const CARD_EDIT_STEP_TWO_EDIT_PARTICIPANT =
  "CARD_EDIT_STEP_TWO_EDIT_PARTICIPANT";
export const CARD_EDIT_STEP_TWO_DELETE_PARTICIPANT =
  "CARD_EDIT_STEP_TWO_DELETE_PARTICIPANT";
export const CARD_EDIT_STEP_TWO_ADD_PASSENGER =
  "CARD_EDIT_STEP_TWO_ADD_PASSENGER";
export const CARD_EDIT_STEP_TWO_EDIT_PASSENGER =
  "CARD_EDIT_STEP_TWO_EDIT_PASSENGER";
export const CARD_EDIT_STEP_TWO_DELETE_PASSENGER =
  "CARD_EDIT_STEP_TWO_DELETE_PASSENGER";
export const STATE_PRESERVE_FILTER_CARD = "STATE_PRESERVE_FILTER_CARD";

export const STATE_RESET_FILTER_CARD = "STATE_RESET_FILTER_CARD";
export const SET_STATISTICS_FILTER = "SET_STATISTICS_FILTER";
export const RESET_STATISTICS_FILTER = "RESET_STATISTICS_FILTER";
