/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useMemo, useEffect, Suspense } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  LayersControl,
} from "react-leaflet";
import L, { DivOverlay } from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "leaflet.heat";
import { BsCircleFill } from "react-icons/all";
import { Radio, Spin, Space } from "antd";

import configs from "../../constants";
import crosswalkLocations from "../../constants/crosswalks";
import { indexById, t } from "../../utils";
import MapStyle from "../../styles/map";
import "../../../node_modules/react-leaflet-markercluster/dist/styles.min.css";
import "../../../node_modules/leaflet/dist/leaflet.css";
import markerIcon from "../../assets/images/pin.png";
import S from "../../styles/statistics";
import Summary from "./Summary";
import Filters from "../../components/Filters";
import Axios, { cancelToken } from "../../utils/axios";
import moment from "moment";
import Reports from "../Reports";
import colors from "../../styles/colors";
import crosswalkImg from "../../assets/images/crosswalk.jpg";

const tileList = {
  osmServer: {
    title: t("Standart"),
    url: `${configs.URL.REACT_APP_TECH_MAP_URL}/tile/{z}/{x}/{y}.png`,
    maxZoom: 20,
    name: "osmServer",
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  },
  googleStreets: {
    title: t("Google Street"),
    url: "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
    maxZoom: 20,
    name: "googleStreets",
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  },
  googleHybrid: {
    title: t("Gibrid"),
    url: "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
    maxZoom: 20,
    name: "googleHybrid",
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
  },
};

const center = {
  lat: 41.2995,
  lng: 69.2401,
};

function HeatMap({ location }) {
  const map = useMap();
  useEffect(() => {
    const layer = L.heatLayer(location, {
      radius: 25,
      max: 0.5,
      maxZoom: 18,
      minOpacity: 0.7,
      gradient: {
        0.4: "blue",
        0.5: "skyblue",
        0.6: "green",
        0.8: "orange",
        1: "red",
      },
    }).addTo(map);
    return () => layer.remove();
  }, [location]);

  return <div></div>;
}

const createClusterCustomIcon = function (cluster, className = false) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: `marker-cluster-custom ${className ? "crosswalk" : ""}`,
    iconSize: L.point(40, 40, true),
  });
};

export default (props) => {
  const [mapType, setMapType] = useState("heat_map");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const defaultListType =
    new URLSearchParams(window.location.search).get("list_type") || "reports";
  const [listType, setListType] = useState(defaultListType);
  const [isCrossWalk, setIsCrossWalk] = useState(true);
  const { accident_types } = useSelector((state) => state.handbooks || {});
  const { lang, region, district } = useSelector(
    (state) => state.auth.user || {}
  );
  const source = cancelToken();
  const history = useHistory();
  const filters = useSelector((state) => state.statisticsFilter || {});

  const filterParams = useMemo(() => {
    let data = "";
    for (let x in filters) {
      if (Array.isArray(filters[x]) && filters[x].length) {
        data += `&${x}=${filters[x].join(",")}`;
      } else if (!Array.isArray(filters[x]) && filters[x]) {
        data += `&${x}=${filters[x]}`;
      }
    }
    return data;
  }, [filters]);

  const fetchData = async (reset = false) => {
    try {
      setLoading(true);
      let queryParams = reset ? "" : filterParams;
      const { data } = await Axios.get(
        `/reports/heat_map/?page_size=10000000${queryParams}`,
        { cancelToken: source.token }
      );
      setData(data.results);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    return () => source.cancel("Canceling fetch reports");
  }, []);

  const accidentTypeIds = useMemo(() => {
    return indexById(accident_types);
  }, [accident_types]);

  useEffect(() => {
    L.icon({
      iconUrl: markerIcon,
      iconSize: [64, 64],
      iconAnchor: [32, 64],
    });
  }, []);

  const locations = useMemo(
    () =>
      data.reduce((acc, i) => {
        if (i.location) {
          acc.push(i.location.split(",").map((i) => +i));
        }
        return acc;
      }, []),
    [data]
  );

  const options = [
    { label: t("Heat Map"), value: "heat_map" },
    { label: t("Cluster Map"), value: "cluster_map" },
  ];

  const { injured, died } = useMemo(
    () =>
      data.reduce(
        (acc, item) => {
          acc.injured += item.injured;
          acc.died += item.died;
          return acc;
        },
        { died: 0, injured: 0 }
      ),
    [data]
  );

  const handleListType = (e) => {
    const type = e.target.value;
    setListType(type);
    history.push(`/statistics/?list_type=${type}`);
  };

  const markers = useMemo(() => {
    return data.map((item, index) => {
      if (item.location?.length) {
        const location = item.location?.split(",").map((item) => +item);
        const { died, injured } = item;
        const status = died > 0 ? "died" : injured > 0 ? "injured" : "healthy";
        const icon = L.divIcon({
          html: `<div></div>`,
          className: `icon ${status}`,
          iconSize: L.point(25, 25, true),
        });

        return (
          <Marker icon={icon} position={location} key={item.id}>
            <Popup minWidth={200} closeButton={false}>
              <div>
                <b>#{item.id}</b>
                <div>
                  {" "}
                  - {new Date(item.date_accident).toLocaleString("ru")}
                </div>
                <div> - {item.location}</div>
                <div className="text-capitalize">
                  {" "}
                  - {accidentTypeIds[item.accident_type]?.[`name_${lang}`]}
                </div>
                <div> - {item.street_name}</div>
                <div>
                  {" "}
                  - {t("Xalok bo'lganlar")} {item.died ?? 0}
                </div>
                <div>
                  {" "}
                  - {t("Jarohatlanganlar")} {item.injured ?? 0}
                </div>
                <Link to={`/cards/${item.id}`}>{t("Ko'rish")}</Link>
              </div>
            </Popup>
          </Marker>
        );
      }
    });
  }, [data]);

  const crosswalks = useMemo(() => {
    return crosswalkLocations.map((item, index) => {
      if (item.location?.length) {
        const location = item.location?.split(",").map((item) => +item);
        if (location[0] && location[1]) {
          const icon = L.divIcon({
            html: `<div><img src=${crosswalkImg} width="40px"/></div>`,
            className: `crosswalks`,
            iconSize: L.point(25, 25, true),
          });

          return (
            <Marker icon={icon} position={location} key={item.id + index}>
              <Popup minWidth={200} closeButton={false}>
                <div>
                  <b>#{item.id}</b>
                  <div>
                    {" "}
                    - {t("Ko'cha nomi")}: "{item.streetName}"
                  </div>
                  <div>
                    {" "}
                    - {t("Qatorlar soni")}: {item.lineCount ?? 0}
                  </div>
                </div>
              </Popup>
            </Marker>
          );
        }
      }
    });
  }, [data]);

  return (
    <S.Main>
      <div className="type-button text-center">
        <Radio.Group size="large" value={listType} onChange={handleListType}>
          <Radio.Button value="reports">{t("Hisobotlar")}</Radio.Button>
          <Radio.Button value="map">{t("Xarita")}</Radio.Button>
        </Radio.Group>
      </div>

      {listType === "reports" ? (
        <Reports params={filterParams} />
      ) : (
        <div className="d-flex page-wrapper mt-5">
          <div className="col-left">
            <div className="d-flex">
              <Radio.Group
                value={mapType}
                className="mb-3"
                options={options}
                optionType="button"
                onChange={(e) => setMapType(e.target.value)}
              />
            </div>
            <MapStyle className="mt-0">
              {loading ? (
                <div className="map-spinner">
                  <Spin size="large" />
                </div>
              ) : null}
              <MapContainer
                zoom={10}
                maxZoom={17}
                scrollWheelZoom={true}
                doubleClickZoom={false}
                center={center}
                minZoom={5}
                // style={{ width: 800, height: 800 }}
              >
                <LayersControl position="topright" collapsed={false}>
                  {Object.keys(tileList).map((item) => {
                    return (
                      <LayersControl.BaseLayer
                        key={item}
                        checked={tileList[item].name === "googleStreets"}
                        name={tileList[item].title}
                      >
                        <TileLayer
                          url={tileList[item].url}
                          subdomains={tileList[item].subdomains}
                          maxZoom={item.maxZoom}
                          projection="EPSG:4326"
                        />
                      </LayersControl.BaseLayer>
                    );
                  })}
                </LayersControl>
                <div class="leaflet-top leaflet-right">
                  <div id="marker-legend">
                    <input
                      checked={isCrossWalk}
                      id="crosswalk"
                      type="checkbox"
                      onChange={(e) => setIsCrossWalk(e.target.checked)}
                    />
                    <label for="crosswalk">
                      <img src={crosswalkImg} width="35px" />
                    </label>
                  </div>
                </div>
                {isCrossWalk ? (
                  <MarkerClusterGroup
                    key={12}
                    showCoverageOnHover={true}
                    zoomToBoundsOnClick={true}
                    iconCreateFunction={(e) => createClusterCustomIcon(e, true)}
                  >
                    {crosswalks}
                  </MarkerClusterGroup>
                ) : (
                  ""
                )}
                {mapType === "cluster_map" ? (
                  <Suspense fallback={<Spin />}>
                    <MarkerClusterGroup
                      key={data.length}
                      showCoverageOnHover={true}
                      zoomToBoundsOnClick={true}
                      iconCreateFunction={createClusterCustomIcon}
                    >
                      {markers}
                    </MarkerClusterGroup>
                  </Suspense>
                ) : (
                  <HeatMap location={locations} />
                )}
              </MapContainer>
            </MapStyle>
            <Space size="large">
              <div className="d-flex">
                <BsCircleFill size="18px" className="mr-1 mb-1" color={"red"} />
                {t("O'lim bilan bog'lik")}
              </div>
              <div className="d-flex">
                <BsCircleFill
                  size="18px"
                  className="mr-1 mb-1"
                  color={"#F59C15"}
                />
                {t("Jarohatlanish")}
              </div>
              <div className="d-flex">
                <BsCircleFill
                  size="18px"
                  className="mr-1 mb-1"
                  color={colors.success}
                />
                {t("Sog'liqqa zarar yetmagan")}
              </div>
            </Space>
            <Summary
              died={died}
              injured={injured}
              total={data.length}
              endDate={filters.date_accident__lte ?? moment()}
              startDate={
                filters.date_accident__gte ??
                moment().startOf("M").utc(true).format()
              }
            />
          </div>
          <div className="b-1 col-right b-radius p-0">
            <Filters
              filters={filters}
              loading={loading}
              fetchData={fetchData}
              statistics
            />
          </div>
        </div>
      )}
    </S.Main>
  );
};
