import axios from "../utils/axios";

export const login = async ({ username, password, type }) => {
  try {
    const res = await axios.post(`/auth/api-token-auth/`, {
      password,
      username,
      type,
    });
    return { phone: res.data.phone, user_id: res.data.user_id, success: true };
  } catch (err) {
    console.error(err);
    console.error(err.response);
    return { error: err, success: false };
  }
};

export const getCurrentUser = async () => {
  try {
    const { data } = await axios.get(`/auth/users/me/`);
    return data;
  } catch (err) {
    return { error: err, success: false };
  }
};
