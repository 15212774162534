import React from 'react';
import { Breadcrumb } from 'antd';
import { BiHome } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function BreadcrumbComponent(props) {
  return (
    <div className="mb-3">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={'/'}><BiHome /></Link>
        </Breadcrumb.Item>
        {
          props.extra.map(item => {
            return (
              <Breadcrumb.Item key={item}>
                <Link to={item.url}>{item.title}</Link>
              </Breadcrumb.Item>
            )
          })
        }
      </Breadcrumb>
    </div>
  )
}

BreadcrumbComponent.defaultProps = {
  extra: []
}

BreadcrumbComponent.propTypes = ({
  url: PropTypes.string,
  title: PropTypes.string,
})