import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../store/auth/actions';
import { login } from '../../api/auth';
import { Alert } from 'antd';
import { t } from '../../utils';
import imageLogo from '../../assets/images/logo.svg';
import imageKashapp from '../../assets/images/kashapp.svg';
import Languages from '../Layout/Language';
import SmsVerification from './SmsVerification';

// import React, { Component } from "react";
import { message, Form, Input, Button, } from "antd";
import S from "../../styles/auth";
import { useHistory } from 'react-router-dom';
import { useRef } from 'react';

const Login = () => {
  const [username, setUsername] = useState('');
  const [confirmationCode, setConfirmationCode] = useState({ user_id: null, phone: '' });
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const parentRef = useRef();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { success, ...data } = await login({ username, password, type: 'internal' });
      if (success) {
        // message.success(t("Muvaffaqiyatli tizimga kirish!"));
        // dispatch(loginAction(res.token));
        setConfirmationCode(data);
        setLoading(false);
        setVisible(true);
      } else {
        setLoading(false);
        setErrorMessage(t("Foydalanuvchi nomi yoki parol noto'g'ri"));
      }
    }
    catch (err) {
      console.log(err)
    }

  };

  const onFinish = values => {
    console.log('Success:', values);
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    document.title = "Учёт ДТП";
  }, []);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const hideModal = () => setVisible(false);

  return (
    <S.StyledSign className="sign">
      <Languages />
      <div className='sign__wrapper'>
        <div className="text-center mb-3">
          <img src={imageLogo} alt="logo" className="logo" />
          <h2 className="mt-3">{t("YTH hisobga olish tizimi")}</h2>
          <h3>{t("Xodimlar uchun kirish")}</h3>
        </div>

        {
          errorMessage && <Alert type="error" className="mb-3 error-message" showIcon description={errorMessage} banner />
        }

        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label={t("Login")}
            name="username"
            rules={[
              {
                required: true,
                message: t("Loginingizni kiriting!"),
              },
            ]}
          >
            <input type="email" hidden />
            <input type="email" hidden />
            <Input
              value={username}
              type="text"
              onChange={event => setUsername(event.target.value)}
              className="input-controller"
              autoComplete="new-email"
            />
          </Form.Item>
          <Form.Item
            label={t('Parol')}
            name="password"
            rules={[{ required: true, message: t("Parolingizni kiriting!") }]}
          >
            <Input.Password
              value={password}
              name="password"
              onChange={event => setPassword(event.target.value)}
              className="input-controller"
              autoComplete="new-password"
            />
          </Form.Item>
          <Button
            onClick={submitHandler}
            className="mt-4"
            type="primary"
            size="large"
            htmlType="submit"
            loading={loading}
          >
            {t("Tizimga kirish")}
          </Button>
        </Form>
      </div>
      <footer>
        <div id="lower-footer">
          <img src={imageKashapp} alt="OOO Kash App" />
          <a href="https://kash.uz/">&copy;{t("2021 KashApp MChJ tomonidan ishlab chiqilgan")}</a>
        </div>
      </footer>
      {
        visible ?
          <SmsVerification
            visible={visible}
            user_id={confirmationCode.user_id}
            phone={confirmationCode.phone}
            hideModal={hideModal}
            parentRef={parentRef}
          />
          :
          null
      }
      {/* <SmsVerification visible={true} confirmationCode={confirmationCode} /> */}
    </S.StyledSign>
  );
}

export default Login;
