import styled from 'styled-components'

const S = {}

S.StyledSign = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .input-controller {
    font-size: 17px;
    svg {
      font-size: 24px;
    }
  }

  .ant-form-item-control-input {
    min-width: 350px;
  }

  .language-list {
    position: absolute;
    right: 40px;
    top: 20px;
  }
  input {
    background-color: transparent;
  }
  
  .ant-btn-primary {
    width: 100%;
  }
  .logo {
    width: 160px;
    margin-top: 60px;
  }
  
  .sign__wrapper {
    margin-bottom: 100px;
  }
  footer {
    margin-top: auto;
    background: #141414;
    width: 100%;
    padding: 20px 0 0;
  }

  #lower-footer {
    padding: 20px 20px 30px;
    background: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    a {
      color: #f8f8f8;
      margin-top: 8px;
    }
  }
`;

S.Auth = styled.div`
  .sms-verification {
    text-align: center;
  }
  .sms-verification__image {
    width: 100px;
    margin: 30px auto;
  }
  h3 {
    color: #818185;
    margin-bottom: 25px;
  }
  .sms-verification__input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    font-size: 20px;

    .has-error {
      border: 1px solid #DE3974;
    }

    label {
      color: #68696e;
      font-size: 13px;
    }

    input {
      width: 100%;
      border-radius: 4px;
      height: 40px;
      border: 1px solid #dedfdf;
      margin-top: 5px;
      padding: 4px 10px;
      font-size: 14px;
      letter-spacing: 6px;
    }
 }
 .link {
   margin-top: 20px;
 } 
 
 .error-message {
   font-size: 12px;
 }
`;
export default S;