import React from 'react'
import { Fragment } from 'react';
import { t } from '../../utils'

export default function ReportHeader({ params }) {
  const paramsObj = new URLSearchParams(params);
  const startDate = paramsObj.get('date_accident__gte');
  const endDate = paramsObj.get('date_accident__lte');

  return (
    <Fragment>
      <tr>
        <td>{t("Hisobot oralig'i")} : {new Date(startDate).toLocaleDateString()}-{new Date(endDate).toLocaleDateString()}</td>
      </tr>
      <tr>
        <td>{"Hisobot yaratilgan sana"}: {new Date().toLocaleDateString()}</td>
      </tr>
    </Fragment>
  )
}
