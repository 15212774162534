import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { t } from '../../utils';
import S from '../../styles/reports';
import { Button } from 'antd';
import { AiOutlineDownload } from 'react-icons/ai';
import { IoIosArrowDown } from "react-icons/io";
import { fetchData } from '../../api/reports';
import ReportHeader from './ReportHeader';

// const DataFormat = [{
//   title: 'Jizzax',
//   currentDate: {
//     date: 2021,
//     data: [{ name: 'Agdarilish', total: 3, died: 2, injured: 1 }],
//     changes: [{ name: 'Agdarilish', total: 3, died: 2, injured: 1 }],
//     changesInPercent: [{ name: 'Agdarilish', total: 3, died: 2, injured: 1 }],
//   },
//   previouseDate: {
//     date: 2020,
//     data: [{ name: 'Agdarilish', total: 10, died: 5, injured: 5 }],
//     changes: [{ name: 'Agdarilish', total: 3, died: 2, injured: 1 }],
//     changesInPercent: [{ name: 'Agdarilish', total: 3, died: 2, injured: 1 }],
//   },

// }]
export default function AccidentTypes({ tableRef, exportToExcel, params, title, type }) {
  const [titles, setTitles] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFetch = async () => {
    setLoading(true);
    const { data, titles, error } = await fetchData({ params, url: 'street_significance' });
    if (!error) {
      setTitles(titles);
      setData(data);
      exportToExcel();
    } else {
      message.error(error)
    }
    setLoading(false);
  }

  return (
    <S.Table>
      <div className="text-right d-flex report-item report-header">
        <div className="report-type">{type}</div>
        <h3>{title}</h3>
        <Button
          type="primary"
          loading={loading}
          onClick={handleFetch}
        >
          {t("Hisobot yaratish")}
          <AiOutlineDownload className="has-icon" />
        </Button>
        <button><IoIosArrowDown /></button>
      </div>

      <div className="hidden-table">
        <table ref={tableRef}>
          <thead>
            <ReportHeader params={params} />
            <tr>
              <th colSpan="2" rowSpan="2"></th>
              {
                titles.map(item => <th key={item} colSpan="3">{item}</th>)
              }
            </tr>

            <tr>
              {
                titles.map((_, index) => {
                  return (
                    <Fragment key={index}>
                      <td>Жами</td>
                      <td>Халок</td>
                      <td>Жарохат</td>
                    </Fragment>
                  )
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              data.map((item, index) => {
                const { title, previouseDate, currentDate } = item;
                return (
                  <Fragment key={index}>
                    <tr>
                      <td rowSpan="2">{title}</td>
                      <td>{previouseDate.date}</td>
                      {
                        previouseDate.data.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <td>{item.total}</td>
                              <td>{item.injured}</td>
                              <td>{item.injured}</td>
                            </Fragment>
                          )
                        })
                      }
                    </tr>
                    <tr>
                      <td>{currentDate.date}</td>
                      {
                        currentDate.data.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <td>{item.total}</td>
                              <td>{item.injured}</td>
                              <td>{item.injured}</td>
                            </Fragment>
                          )
                        })
                      }
                    </tr>
                    <tr>
                      <td>Усиш/камайиш</td>
                      <td></td>
                      {
                        previouseDate.changesInPercent.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <td>{item.total}</td>
                              <td>{item.injured}</td>
                              <td>{item.injured}</td>
                            </Fragment>
                          )
                        })
                      }
                    </tr>
                    <tr>
                      <td>Усиш/камайиш(%)</td>
                      <td></td>
                      {
                        previouseDate.changesInPercent.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <td>{item.total}</td>
                              <td>{item.injured}</td>
                              <td>{item.injured}</td>
                            </Fragment>
                          )
                        })
                      }
                    </tr>
                  </Fragment>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </S.Table>
  )
}

AccidentTypes.propTypes = {
  exportToExcel: PropTypes.func.isRequired,
  params: PropTypes.string,
  title: PropTypes.string,
}

AccidentTypes.defaultProps = {
  params: '',
  title: 'report'
}