import React, { useState, useEffect, } from 'react';
import { t, } from '../../utils';
import { Form, Input, Button, Select, Row, Col, message, Divider, Alert, } from 'antd';
import { useSelector } from 'react-redux';
import axios from '../../utils/axios';
import Swal from 'sweetalert2';
import colors from '../../styles/colors';
import { AiOutlineLock, } from 'react-icons/ai';
import S from '../../styles/user';

const { Option } = Select;

export default function PersonalInfo({ hideModal, fetchUsers }) {
  const myAccount = useSelector(state => state.auth.user || {});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { regions } = useSelector(state => state.handbooks || {});
  const errorsList = {
    password: t('Parol'),
    username: t('Login'),
  }
  const [errors, setErrors] = useState({});
  const { lang } = myAccount;
  const [user, setUser] = useState({
    username: '',
    password: '',
    region: null,
    type: 'external',
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get('/auth/groups/?page_size=100');
      }
      catch (err) {
        console.error(err);
      }
    })()
  }, []);

  const handleInput = val => {
    let data = val;
    if (Object.keys(val)[0] === 'region') {
      data = { ...val, district: null };
      form.setFieldsValue(data);
    }
    setUser(state => ({ ...state, ...data }))
  }

  const handleSave = async (values) => {
    // setErrors({})
    setLoading(true);
    Swal.fire({
      title: t("Ishonchingiz komilmi?"),
      icon: "warning",
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: '#E7E9EB',
      confirmButtonColor: colors.success,
      showCancelButton: true,
      customClass: 'swal-danger',
    }).then(async ({ value }) => {
      if (value) {
        try {
          let request = {
            type: 'external',
            username: values.username,
            password: values.password,
            region: values.region,
          }

          if (Object.keys(request).length) {
            const { data } = await axios.post(`/auth/users/`, request);
            message.success(t("Foydalanuvchi muvaffaqiyatli yaratildi"));
            fetchUsers()
            hideModal();
          } else {
            message.error(t("Yangilanadigan ma'lumot yo'q."))
          }
        }
        catch (err) {
          console.error(err);
          const data = err.response.data;
          let messages = [];
          for (let x in data) {
            setErrors(state => ({ ...state, [x]: data[x] }));
            messages.push(<li style={{ maxWidth: 600, textAlign: 'left', alignSelf: 'flex-start' }} key={x}><b>{errorsList[x] ? errorsList[x] : x}</b>: {data[x].join(', ')}</li>)
          }
          // return message.error(messages, 70);
        }
      }

    });
    setLoading(false);

  }

  return (
    <S.AddUser>
      {
        Object.values(errors).length ?
          <Alert
            showIcon
            type="error"
            className="my-3"
            message={<ul style={{ padding: '7px 20px 0px' }}>
              {Object.values(errors).map(i => <li key={i}>{i}</li>)}
            </ul>}
          />
          :
          null
      }

      <Form
        name="basic"
        onFinish={handleSave}
        form={form}
        className="my-4"
        initialValues={user}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        onValuesChange={handleInput}
      >
        <Row gutter={[20, 0]}>
          <Col span={24}>
            <Form.Item
              name="region"
              label={t("Viloyat")}
              rules={[
                {
                  required: false,
                  message: "Ma'lumot kiritish majburiy"
                },
              ]}
            >
              <Select
                showSearch
                placeholder={t('Viloyat')}
                autoComplete="off"
                name={'region'}
                showAction={["focus", "click"]}
                allowClear
                className="custom-select-content focusable mt-2"
                dropdownClassName="custom-select-dropdown focusable"
                size="large"
                listItemHeight={42}
                filterOption={(input, option) => {
                  return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }}
                disabled={!myAccount.is_superuser && !user.is_superuser}
              >
                {
                  regions.map(item => {
                    const translationBasedName = item[`name_${lang}`] || item['name'];
                    return <Option
                      value={item.id}
                      label={translationBasedName}
                      key={item.id}
                    >
                      {translationBasedName}
                    </Option>
                  })
                }
              </Select >
            </Form.Item>
          </Col>
          <Col span={24} >
            <Divider />
            <h2 style={{ color: colors.success }}><AiOutlineLock /> {t("Xavfsizlik ma'lumotlar")}</h2>
          </Col>
          <input type="email" hidden />
          <input type="email" hidden />

          <Col span={24}>
            <Form.Item
              name="username"
              label={t("Login")}
              rules={[
                {
                  required: true,
                  message: t("Login majburiy")
                },
              ]}
            >
              <Input
                placeholder={t("Login")}
                autoComplete="off"
                name="username"
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <input type="password" hidden />
          <input type="password" hidden />
          <Col span={24} md={12}>
            <Form.Item
              name="password"
              label={t("Parol")}
              rules={[
                {
                  required: true,
                  message: t("Parol majburiy")
                },
              ]}
            >
              <Input.Password
                placeholder={t("Parol")}
                autoComplete="new-password"
                name="password"
                className="input-controller focusable mt-2"
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name="confirm"
              label={t('Parolni tasdiqlash')}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('Iltimos Parolni tasdiqlang'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t("Siz kiritgan ikkita parol mos emas!")));
                  },
                }),
              ]}
            >
              <Input.Password className="input-controller focusable mt-2" />
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          style={{ width: '100%' }}
          htmlType="submit"
          size="large"
          loading={loading}
        >
          {t("Saqlash")}
        </Button>
      </Form>
    </S.AddUser>
  )
}
