import Layout from './Layout';
import { useSelector } from 'react-redux';
import routes from '../routes';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './Auth/Login';

function App() {
  const auth = useSelector(state => state.auth || {});
  const { token, user = {} } = auth;
  const allowedRoutes = user?.read_only ? routes.filter(item => item.readOnly) : user.district ? routes.filter(item => !item.protected) : routes.filter(item => !item.protected || item.allowed?.includes('region'));
  const adminRoutes = user?.is_superuser ? routes : allowedRoutes;

  if (token) {
    return (
      <>
        <Layout>

          <Switch>
            {adminRoutes.map(item => {
              return <Route {...item} key={item.path} />
            })}
            <Redirect to="/cards/" />
          </Switch>
        </Layout>
      </>
    )
  }

  return <Login />
}

export default App;
