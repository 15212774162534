/* eslint-disable import/no-anonymous-default-export */

import colors from './colors';

export default `
  .d-none{
    display: none;
  }
  .w-100 {
    width: 100%;
  }
  .w-50 {
    width: 50%;
  }
  .w-75 {
    width: 75%;
  }
  .w-25 {
    width: 25%;
  }
  .b-0 {
    border: none;
  }
  .by-0 {
    border-top: 0px solid ${colors.borderLight};
    border-bottom: 0px solid ${colors.borderLight};
  }
  .bl-0 {
    border-left: 0px solid ${colors.borderLight};
  }
  .br-0 {
    border-right: 0px solid ${colors.borderLight};
  }
  .bb-0 {
    border-bottom: 0px solid ${colors.borderLight};
  }
  .bt-0 {
    border-top: 0px solid ${colors.borderLight};
  }
  .b-1 {
    border: 1px solid ${colors.borderLight};
  }
  .bx-1 {
    border-left: 1px solid ${colors.borderLight};
    border-right: 1px solid ${colors.borderLight};
  }
  .by-1 {
    border-top: 1px solid ${colors.borderLight};
    border-bottom: 1px solid ${colors.borderLight};
  }
  .bl-1 {
    border-left: 1px solid ${colors.borderLight};
  }
  .br-1 {
    border-right: 1px solid ${colors.borderLight};
  }
  .bb-1 {
    border-bottom: 1px solid ${colors.borderLight};
  }
  .bt-1 {
    border-top: 1px solid ${colors.borderLight};
  }
  .b-radius {
    border-radius: 5px;
  }
  .b-radius-10 {
    border-radius: 10px;
  }
  .b-radius-left {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  .b-radius-left-top {
    border-top-left-radius: 5px;
  }
  .b-radius-left-bottom {
    border-bottom-left-radius: 5px;
  }
  .b-radius-right {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
  .b-radius-right-bottom {
    border-bottom-right-radius: 5px;
  }
  .b-radius-right-top {
    border-top-right-radius: 5px;
  }
  .b-radius-left-10 {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }
  .b-radius-left-top-10 {
    border-top-left-radius: 10px;
  }
  .b-radius-left-bottom-10 {
    border-bottom-left-radius: 10px;
  }
  .b-radius-right-10 {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .b-radius-right-bottom-10 {
    border-bottom-right-radius: 10px;
  }
  .b-radius-right-top-10 {
    border-top-right-radius: 10px;
  }
  .m-0 {
    margin: 0 !important;
  }
  .m-1 {
    margin: 0.25rem !important;
  }
  .m-2 {
    margin: 0.5rem !important;
  }
  .m-3 {
    margin: 1rem !important;
  }
  .m-4 {
    margin: 1.5rem !important;
  }
  .m-5 {
    margin: 3rem !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-0 {
    margin-top: 0 !important;
  }
  .mt-1 {
    margin-top: 0.25rem !important;
  }
  .mt-2 {
    margin-top: 0.5rem !important;
  }
  .mt-3 {
    margin-top: 1rem !important;
  }
  .mt-4 {
    margin-top: 1.5rem !important;
  }
  .mt-5 {
    margin-top: 3rem !important;
  }
  .mt-auto {
    margin-top: auto !important;
  }
  .mr-0 {
    margin-right: 0 !important;
  }
  .mr-1 {
    margin-right: 0.25rem !important;
  }
  .mr-2 {
    margin-right: 0.5rem !important;
  }
  .mr-3 {
    margin-right: 1rem !important;
  }
  .mr-4 {
    margin-right: 1.5rem !important;
  }
  .mr-5 {
    margin-right: 3rem !important;
  }
  .mr-auto {
    margin-right: auto !important;
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-5 {
    margin-bottom: 3rem !important;
  }
  .mb-auto {
    margin-bottom: auto !important;
  }
  .ml-0 {
    margin-left: 0 !important;
  }
  .ml-1 {
    margin-left: 0.25rem !important;
  }
  .ml-2 {
    margin-left: 0.5rem !important;
  }
  .ml-3 {
    margin-left: 1rem !important;
  }
  .ml-4 {
    margin-left: 1.5rem !important;
  }
  .ml-5 {
    margin-left: 3rem !important;
  }
  .ml-auto {
    margin-left: auto !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .p-1 {
    padding: 0.25rem !important;
  }
  .p-2 {
    padding: 0.5rem !important;
  }
  .p-3 {
    padding: 1rem !important;
  }
  .p-4 {
    padding: 1.5rem !important;
  }
  .p-5 {
    padding: 3rem !important;
  }
  .p-auto {
    padding: auto !important;
  }
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-auto {
    padding-right: auto !important;
    padding-left: auto !important;
  }
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-auto {
    padding-top: auto !important;
    padding-bottom: auto !important;
  }
  .pt-0 {
    padding-top: 0 !important;
  }
  .pt-1 {
    padding-top: 0.25rem !important;
  }
  .pt-2 {
    padding-top: 0.5rem !important;
  }
  .pt-3 {
    padding-top: 1rem !important;
  }
  .pt-4 {
    padding-top: 1.5rem !important;
  }
  .pt-5 {
    padding-top: 3rem !important;
  }
  .pt-auto {
    padding-top: auto !important;
  }
  .pr-0 {
    padding-right: 0 !important;
  }
  .pr-1 {
    padding-right: 0.25rem !important;
  }
  .pr-2 {
    padding-right: 0.5rem !important;
  }
  .pr-3 {
    padding-right: 1rem !important;
  }
  .pr-4 {
    padding-right: 1.5rem !important;
  }
  .pr-5 {
    padding-right: 3rem !important;
  }
  .pr-auto {
    padding-right: auto !important;
  }
  .pb-0 {
    padding-bottom: 0 !important;
  }
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-3 {
    padding-bottom: 1rem !important;
  }
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-5 {
    padding-bottom: 3rem !important;
  }
  .pb-auto {
    padding-bottom: auto !important;
  }
  .pl-0 {
    padding-left: 0 !important;
  }
  .pl-1 {
    padding-left: 0.25rem !important;
  }
  .pl-2 {
    padding-left: 0.5rem !important;
  }
  .pl-3 {
    padding-left: 1rem !important;
  }
  .pl-4 {
    padding-left: 1.5rem !important;
  }
  .pl-5 {
    padding-left: 3rem !important;
  }
  .pl-auto {
    padding-left: auto !important;
  }
`;