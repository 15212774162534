/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
const colors = {
  border: "#C3C5C9",
  borderLight: "#E2E4E7",
  danger: "#DE3974",
  dangerLight: "#ffeef3",
  darkGrey: "#E7E9EB",
  grey: "#FAFAFA",
  greyText: "#C4C4C4",
  secondary: " #7C818B",
  success: "#42C293",
  successLight: "#EDFAF6",
  text: "#333333",
  orange: "#DE7439",
  blue: "#39C0DE",
};

Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports["default"] = colors;
