import React, { useEffect } from "react";
import { Modal, Button } from "antd";
import imageSms from "../../assets/images/sms.svg";
import { t } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import S from "../../styles/auth";
import { useRef } from "react";
import axios from "../../utils/axios";
import { useState } from "react";
import { loginAction } from "../../store/auth/actions";
import InputMask from "react-input-mask";

export default function SmsVerification({
  user_id,
  visible = false,
  phone = "",
  hideModal,
}) {
  const { lang = "uz" } = useSelector((state) => state.auth.user);
  const [smsCode, setSmsCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const parentRef = useRef();
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const title = {
    uz: `Profilingizga bog'langan  ****{${phone}} telefon raqamiga SMS yuborildi.`,
    ru: `SMS был отправлен на номер телефона ****{${phone}}, привязанный к вашему профилю.`,
    oz: `SMS был отправлен на номер телефона ****{${phone}}, привязанный к вашему профилю.`,
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`/auth/api-token-auth/verify/`, {
        user_id,
        sms_code: +smsCode,
      });
      console.log(res);
      dispatch(loginAction(res.data.token));
    } catch (err) {
      setLoading(false);
      console.log(err);
      setError(t("Kod hato"));
    }
  };

  useEffect(() => {
    if (!smsCode.includes("_") && smsCode) {
      handleLogin();
    }
  }, [smsCode]);

  useEffect(() => {
    if (inputRef.current.mounted) {
      setTimeout(() => {
        inputRef.current?.getInputDOMNode().focus();
      }, 10);
    }
    if (visible) {
    }
  }, [visible]);

  return (
    <S.Auth ref={parentRef}>
      <Modal
        visible={visible}
        onCancel={hideModal}
        className="sms-verification"
        footer={null}
        width={400}
        getContainer={() => parentRef.current}
      >
        <img
          src={imageSms}
          alt="phon verification"
          className="sms-verification__image"
        />
        <h3>{title[lang]}</h3>
        <div className="sms-verification__input-wrapper">
          <label htmlFor="">
            {t("Tasdiqlash kodini kiriting")}({t("5 raqmli kod")})
          </label>
          <InputMask
            ref={inputRef}
            name="accident_time"
            className="input-controller focusable"
            value={smsCode}
            mask="99999"
            maskChar="_"
            inputMode="numeric"
            alwaysShowMask="true"
            placeholder={t("5 raqmli kod")}
            onChange={(e) => setSmsCode(e.target.value)}
          />
          {/* <input
            type="number"
            value={smsCode}
            onChange={e => setSmsCode(e.target.value)}
            placeholder={t('5 raqmli kod')}
            maxLength={5}
            className={error ? 'has-error' : ''}
          /> */}
          <span className="error-message">{error}</span>
        </div>
        <Button
          loading={loading}
          type="primary"
          onClick={handleLogin}
          className="save-btn"
        >
          {t("Tizimga kirish")}
        </Button>
        <Button type="link" onClick={hideModal} className="link">
          {t("Bekor qilish")}
        </Button>
      </Modal>
    </S.Auth>
  );
}
