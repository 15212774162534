import React from 'react';
import pageNotFoundImage from '../assets/images/404.svg';
import { t } from '../utils';
import SignInStyled from '../styles/pageNotFound';

export default function NotFound(props) {
  return (
    <SignInStyled>
      <img src={pageNotFoundImage} alt="not found page" />
      <h1>{t("Sahifa topilmadi")}</h1>
    </SignInStyled>
  )
}
