import * as types from '../actionTypes';
import moment from 'moment';


const initialState = {
  permissions: [],
  lastModified: null,
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PERMISSIONS_SET:
    case types.PERMISSIONS_UPDATE: {
      return { ...state, permissions: action.data, lastModified: moment(), }
    }

    case types.AUTH_SIGN_OUT:
    case types.PERMISSIONS_DELETE: {
      return initialState
    }

    default: {
      return state;
    }
  }
}


export default permissionsReducer;