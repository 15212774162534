/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, lazy, Suspense } from 'react';
import { Table, Input, Button, Divider, Spin, Switch, message, Menu, Dropdown, Tag } from 'antd';
import Axios, { cancelToken } from '../../utils/axios';
import { indexById, t } from '../../utils';
import S from '../../styles/user';
import { BiPlusCircle } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from 'antd/lib/modal/Modal';
import { AiOutlineDelete, AiOutlineMore } from 'react-icons/ai';
import colors from '../../styles/colors';
import Swal from 'sweetalert2';

const AddUser = lazy(() => import('./AddUser'));

export default function Users(props) {
  const source = cancelToken();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, total: 0, size: 10 });
  const myAccount = useSelector(state => state.auth.user);
  const { districts, regions } = useSelector(state => state.handbooks || {});
  const defaultPage = new URLSearchParams(window.location.search).get('page') || 1;
  const defaultSize = new URLSearchParams(window.location.search).get('page_size') || 10;
  const history = useHistory();
  const { allRegions = [], allDistricts = [] } = useMemo(() => {
    return {
      allDistricts: indexById(districts),
      allRegions: indexById(regions),
    }
  }, [regions, districts]);

  useEffect(() => {
    handlePage();
    return () => source.cancel('Component unmounted')
  }, []);

  const handleDelete = async (id) => {
    Swal.fire({
      title: t("Ishonchingiz komilmi?"),
      text: t("Haqiqatdanham foydalanuvchini o'chirmoqchimisiz?"),
      icon: "warning",
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: '#E7E9EB',
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: 'swal-danger',
    }).then(async ({ value }) => {
      if (value) {
        try {
          setLoading(true);
          await Axios.delete(`/auth/users/${id}/`);
          await handlePage();
          message.success(t("Ma'lumot muvaffaqiyatli yangilandi"));
        }
        catch (err) {
          console.error(err);
          setLoading(false);
        }
      }
    })
  }

  const handleStatus = async ({ id, is_active }) => {
    Swal.fire({
      title: t("Ishonchingiz komilmi?"),
      text: t(""),
      icon: "warning",
      confirmButtonText: t("Tasdiqlash"),
      cancelButtonText: t("Bekor qilish"),
      cancelButtonColor: '#E7E9EB',
      confirmButtonColor: colors.danger,
      showCancelButton: true,
      customClass: 'swal-danger',
    }).then(async ({ value }) => {
      if (value) {
        try {
          setLoading(true);
          await Axios.patch(`/auth/users/${id}/`, { is_active });
          await handlePage();
          message.success(t("Ma'lumot muvaffaqiyatli yangilandi"));
        }
        catch (err) {
          console.error(err);
          setLoading(false);
        }
      }
    })
  }

  const handlePage = async (page = defaultPage, size = defaultSize) => {
    try {
      setLoading(true);
      const query = myAccount.region ? `&region=${myAccount.region}` : '';
      const res = await Axios.get(`/auth/users/?type=internal&page=${page}&page_size=${size}${query}`, { cancelToken: source.token });
      if (res.data.results?.length) {
        setData(res.data.results);
        setPagination({ page: parseInt(page), total: res.data.count, size });
        history.push(`/users/?page=${page}&page_size=${size}`);
      }
      setLoading(false);
    }
    catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  const columns = useMemo(() => [
    {
      title: t('#'),
      dataIndex: 'id',
      width: 30,
      render: (val, record, index) => {
        const { page, size } = pagination;
        const number = page > 1 ? (page - 1) * size + index + 1 : index + 1
        return number;
      }
    },
    {
      title: t('F.I.SH'),
      dataIndex: 'first_name',
      render: (val, record) => `${record.first_name ?? ''} ${record.last_name ?? ''} ${record.middle_name ?? ''}`,
    },
    {
      title: t('Viloyat'),
      dataIndex: 'region',
      render: (val, record) => val && allRegions[val] ? allRegions[val][`name_${myAccount.lang}`] : '',
      filters: regions.map(item => ({ text: item[`name_${myAccount.lang}`], value: item.id })),
      filterMultiple: false,
      onFilter: (value, record) => record.region === value,
    },
    {
      title: t('Tuman'),
      dataIndex: 'district',
      render: (val, record) => val ? allDistricts[val][`name_${myAccount.lang}`] : '',
    },
    {
      title: t('Telefon'),
      dataIndex: 'phone',
    },
    {
      title: t("Holati"),
      dataIndex: 'is_active',
      render: (val) => <Tag className={val ? 'active' : 'inactive'}>{val ? t('Faol') : t("Nofaol")}</Tag>,
      filters: [
        {
          text: t('Faol'),
          value: true,
        },
        {
          text: t("Nofaol"),
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.is_active === value,
    },
    {
      title: t("O'chirish"),
      dataIndex: 'actions',
      render: (val, record) => {
        const menu = <Menu>
          <Menu.Item
            onClick={() => handleDelete(record.id)}
            className="text-center py-2 mb-2"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <span className="mr-1">{t("O'chirish")}</span> <AiOutlineDelete size={24} color={colors.danger} />
          </Menu.Item>
          <Menu.Item className="py-2 mb-2">
            <span className="mr-1">{t(record.is_active ? 'Faol' : "Nofaol")}</span>
            <Switch
              size="small"
              checked={record.is_active}
              onChange={(value) => handleStatus({ id: record.id, is_active: value })}
            />
          </Menu.Item>
        </Menu>
        if (record.id !== myAccount.id) {
          return (
            <div onClick={e => e.stopPropagation()}>
              <Dropdown overlay={menu} placement="bottomLeft" arrow>
                <Button type="ghost" className="more-btn focusable"><AiOutlineMore size={24} /></Button>
              </Dropdown>
            </div>
          )
        }
      }
    }
  ], [data, myAccount.lang, allDistricts, allRegions]);

  return (
    <S.List>
      <div className="px-4">
        {/* <h1>{t('Foydalanuvchilar')}</h1> */}
        <div className="d-flex mb-4 mt-4">
          <Input.Search id="search-bar" />
          <Button onClick={() => setVisible(true)} id="add-card" type="primary" >
            {t("Foydalanuvchi qo'shish")}
            {<BiPlusCircle className="has-icon" />}
          </Button>
        </div>
      </div>
      <Divider style={{ marginLeft: -40, width: 'calc(100% + 40px)' }} />
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        className="card-list"
        rowKey="id"
        scroll={{ x: true }}
        rowClassName="clickable"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => record.id !== myAccount.id ? history.push(`/users/${record.id}/`) : history.push(`/profile/`), // click row
          };
        }}
        pagination={{
          hideOnSinglePage: false,
          pageSize: pagination.size,
          current: pagination.page,
          total: pagination.total,
          showSizeChanger: true,
          showSizeChangerOptions: {},
          onChange: (page, size) => handlePage(page, size),
          pageSizeOptions: [20, 30, 50],
          showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,
          locale: { items_per_page: '/ ' + t('Sahifa') }
        }}
      />

      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        zIndex={1050}
        width="90%"
        style={{ maxWidth: 700 }}
        footer={() => null}
      >
        {
          visible ?
            (
              <Suspense fallback={<Spin />}>
                <AddUser fetchUsers={handlePage} hideModal={() => setVisible(false)} />
              </Suspense>
            )
            :
            null
        }
      </Modal>


    </S.List >
  )
}
