import * as types from '../actionTypes';

const initialState = {};

const cardEditReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.CARD_PERSIST: {
      return {
        ...state,
        ...action.data,
      }
    }
    case types.CARD_EDIT_COMPLETED:
    case types.CARD_EDIT_DELETE:
    case types.CARD_PERSIST_DELETE:
    case types.AUTH_SIGN_OUT: {
      return initialState
    }
    default: {
      return state;
    }
  }
}


export default cardEditReducer;