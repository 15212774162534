import moment from 'moment';
import store from '../store';

function momentLocale() {
  const lang = store.getState().auth?.user?.lang || 'uz';

  const uz = {
    months: ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'Iyun', 'Iyul', 'Avgust', 'Sentyabr', 'Oktyabr', 'Noyabr', 'Dekabr'],
    monthsShort: ['Yan', 'Fev', 'Mar', 'Apr', 'May', 'Iyun', 'Iyul', 'Avg', 'Sent', 'Okt', 'Noy', 'Dek'],
    monthsParseExact: true,
    weekdays: ['Dushanba', 'Seshanba', 'Chorshanba', 'Payshanba', 'Juma', 'Shanba', 'Yakshanba'],
    weekdaysShort: ['Du', 'Se', 'Cho', 'Pay', 'Ju', 'Sha', 'Yak'],
    weekdaysMin: ['Du', 'Se', 'Cho', 'Pay', 'Ju', 'Sha', 'Yak'],
    weekdaysParseExact: true,
    calendar: {
      lastDay: '[Kecha] LT',
      sameDay: '[Bugun] LT',
      nextDay: '[Ertaga] LT',
      lastWeek: '[O\'tgan] dddd [da] LT',
      nextWeek: 'dddd [da] LT',
      sameElse: 'L'
    }
  }

  const oz = {
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthsShort: ['Ян', 'Фев', 'Ма', 'Апр', 'Ма', 'Июн', 'Июл', 'Авг', 'Сен', 'Ок', 'Но', 'Де'],
    monthsParseExact: true,
    weekdays: ['Душанба', 'Сешанба', 'Чоршанба', 'Пайшанба', 'Жума', 'Шанба', 'Якшанба'],
    weekdaysShort: ['Ду', 'Се', 'Чор', 'Пай', 'Жу', 'Шан', 'Як'],
    weekdaysMin: ['Ду', 'Се', 'Чор', 'Пай', 'Жу', 'Шан', 'Як'],
    weekdaysParseExact: true,
    calendar: {
      lastDay: '[Кеча] LT',
      sameDay: '[Бугун] LT',
      nextDay: '[Эртага] LT',
      lastWeek: '[Ўтган] dddd [да] LT',
      nextWeek: 'dddd [да] LT',
      sameElse: 'L'
    }
  };

  const ru = {
    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthsShort: ['Ян', 'Фев', 'Ма', 'Апр', 'Ма', 'Июн', 'Июл', 'Авг', 'Сен', 'Ок', 'Но', 'Де'],
    monthsParseExact: true,
    weekdays: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
    weekdaysShort: ['По', 'Вт', 'Ср', 'Че', 'Пя', 'Су', 'Во'],
    weekdaysMin: ['По', 'Вт', 'Ср', 'Че', 'Пя', 'Су', 'Во'],
    weekdaysParseExact: true,
    calendar: {
      lastDay: '[Bчера] LT',
      sameDay: '[Сегодня] LT',
      nextDay: '[Завтра] LT',
      lastWeek: '[Последний] dddd [в] LT',
      nextWeek: 'dddd [в] LT',
      sameElse: 'L'
    }
  };

  switch (lang) {
    case 'uz':
      moment.updateLocale(lang, uz);
      return moment;
    case 'oz':
      moment.updateLocale(lang, oz);
      return moment;
    default:
      moment.updateLocale(lang, ru);
      return moment;
  }

}

export default momentLocale;