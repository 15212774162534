import React from 'react';
import { Input } from 'antd';
import { t } from '../utils';

const HandbookSearch = ({ query = '', className, style, clearHandler, searchHandler, queryHandler }) => {
  return (
    <Input.Search
      size="large"
      style={{ maxWidth: 300, ...style }}
      placeholder={t('Qidirish')}
      className={`${className} handbook-search`}
      value={query}
      suffix={query.length ? <button className="clear-btn" style={{ height: 25 }} onClick={clearHandler}>X</button> : <span />}
      onChange={(e) => {
        const { value } = e.target;
        queryHandler(value);
      }}
      onSearch={searchHandler}
      onPressEnter={searchHandler}
    />
  );
}

export default HandbookSearch;
