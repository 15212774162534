import * as types from '../actionTypes';
import moment from 'moment';


const initialState = {
  accident_causals: [],
  accident_types: [],
  belong_types: [],
  health_conditions: [],
  hospitals: [],
  illuminations: [],
  licence_categories: [],
  road_conditions: [],
  regions: [],
  road_parts: [],
  road_surfaces: [],
  taken_measures: [],
  technical_issues: [],
  vehicle_brands: [],
  vehicle_colors: [],
  vehicle_models: [],
  vehicle_types: [],
  violations: [],
  weather_conditions: [],
  lastModified: null,
};

const handbookReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HANDBOOKS_SET:
    case types.HANDBOOKS_UPDATE: {
      return { ...state, ...action.data, lastModified: moment(), }
    }

    case types.AUTH_SIGN_OUT:
    case types.HANDBOOKS_DELETE: {
      return initialState
    }

    default: {
      return state;
    }
  }
}


export default handbookReducer;