import * as types from "../actionTypes";

const actions = {};
actions.preserveState = (payload) => ({
  type: types.STATE_PRESERVE_FILTER_CARD,
  payload,
});
actions.resetState = () => ({ type: types.STATE_RESET_FILTER_CARD });

export default actions;
